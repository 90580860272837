import { LoginScreen, useLogin } from '../../pages/login/LoginContext';
import './blur.css';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

export const Blur = () => {
    const { ActiveScreen } = useLogin();

    const blurRef = useRef();

    useEffect(() => {
        if (ActiveScreen === LoginScreen.MAIN_SCREEN) {
            blurRef.current.classList.remove('active');
        } else {
            blurRef.current.classList.add('active');
        }
    }, [ActiveScreen]);

    return (
        <div className='blur_modal' ref={blurRef}>

        </div>
    );
};