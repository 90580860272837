import './checkout_payment_method.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { CashItem } from '../CashItem/CashItem';
import { useAuth } from '../../context/AuthContext';
import { PAYMENT_METHODS, usePaymentMethods } from '../../context/PaymentMethodsContext';
import { useEffect, useState } from 'react';
import { SavedCardItem } from '../SavedCardItem/SavedCardItem';

export const CheckoutPaymentMethod = ({nochange}) => {
    //HOOKS
    const { changeAppScreen } = useApp();
    const { user } = useAuth();
    const { getSavedCard, savedCards } = usePaymentMethods();

    //STATES
    const [selectedCard, setSelectedCard] = useState(null);

    //EFFECTS
    useEffect(() => {
        if (user !== null && user.payment.payment_method === PAYMENT_METHODS.STRIPE && savedCards.length > 0) {
            setSelectedCard(getSavedCard(user.payment.default_card));
        } else {
            setSelectedCard(null);
        }
    }, [user, savedCards]);

    return (
        <div className="checkout_payment_method">
            <div className="info">
                <h2>
                    Payment method
                </h2>
                {
                    !nochange && (
                        <button onClick={() => changeAppScreen(APP_SCREENS.PAYMENT_METHODS)}>
                            Change
                        </button>
                    )
                }
            </div>
            {
                user && (user.payment.payment_method === PAYMENT_METHODS.CASH) && (
                    <CashItem displayMode={true} />
                )
            }
            {
                user && (user.payment.payment_method === PAYMENT_METHODS.STRIPE) && selectedCard &&(
                    <SavedCardItem card={selectedCard} displayMode={true} />
                )
            }
        </div>
    )
}