import { createContext, useContext, useEffect, useState } from "react";
import { APP_SCREENS, useApp } from "./AppContext";
import { useAuth } from "./AuthContext";
import { PAYMENT_METHODS } from "./PaymentMethodsContext";
import { DELIVERY_METHODS, useAddressCreate } from "./AddressCreateContext";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { useCart } from "./CartContext";

const CheckoutContext = createContext(undefined);

export const CheckoutProvider = ({children}) => {
    //HOOKS
    const { changeAppScreen } = useApp();
    const { user, getUserDeliveryAddress, checkAuth } = useAuth();
    const { postRequest } = useServer();
    const { globalCurrentAddress } = useAddressCreate();
    const { cart } = useCart();

    //STATES
    const [tip, setTip] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [checkoutResult, setCheckoutResult] = useState(null);
    const [usePoints, setUsePoints] = useState(false);
    const [pointsToUse, setPointsToUse] = useState(0);

    
    const [total, setTotal] = useState(0);

    //FUNCTIONS
    const checkout = () => {
        if (checkoutLoading) {
            return;
        }

        changeAppScreen(APP_SCREENS.CHECKOUT_LOADING);
        setCheckoutLoading(true);
        setCheckoutResult(null);

        const data = createCheckoutData();

        postRequest(endpoints.chekout, data, (res) => {
            setCheckoutLoading(false);

            if (res[0]) {
                res = res[1];
                setCheckoutResult(res);

                if (res.success)
                {
                    checkAuth();
                }
            } else {
                setCheckoutResult({success: false, "error_code" : "server_error"});
            }
        });
    }

    const createCheckoutData = () => {
        let data = {
            "checkout_version": 1,
            "order_details" : {
                "delivery_type" : user.delivery.main_delivery_method,
                "payment_method" : user.payment.payment_method === PAYMENT_METHODS.CASH ? PAYMENT_METHODS.CASH : 4,
                "platform" : "application",
                "tip": tip,
                "use_points": usePoints,
                "points" : pointsToUse
            },
            "contact" : {
                "name" : name,
                "email" : email,
                "phone" : phone
            }
        }

        if (user.delivery.main_delivery_method == DELIVERY_METHODS.DELIVERY) {
            const address = getUserDeliveryAddress(user.delivery.main_address);
            data.delivery_details = {
                fullAddress: address.address,
                apartment: address.apartment,
                details: address.details,
                entrance: address.entrance,
                floor: address.floor,
                latitude: parseFloat(address.latitude),
                longitude: parseFloat(address.longitude),
            }
        }

        return data;
    }

    //EFFECTS
    useEffect(() => {
        if (cart != null) {
            console.log(cart);
            let total = cart.total;

            if (globalCurrentAddress !== null) {
                total += globalCurrentAddress.area.price;
            }

            total += parseFloat((tip !== "") ? tip : "0");

            if (usePoints) {
                const maxPoints = parseInt(total * 100);
                if (user.points.points >= maxPoints) {
                    setPointsToUse(maxPoints);
                    total = 0;
                } else {
                    setPointsToUse(user.points.points);
                    total -= (user.points.points / 100);
                }
            } else {
                setPointsToUse(0);
            }

            setTotal(total);
        }
    }, [cart, globalCurrentAddress, tip, usePoints]);

    const values = {
        tip,
        setTip,
        checkout,
        checkoutLoading,
        name,
        setName,
        email,
        setEmail,
        phone,
        setPhone,
        checkoutResult,
        usePoints,
        setUsePoints,
        total,
        setTotal,
        pointsToUse,
    }

    return (
        <CheckoutContext.Provider
            value={values}
        >
            {children}
        </CheckoutContext.Provider>
    )
}

export const useCheckout = () => useContext(CheckoutContext);