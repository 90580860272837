import { useRestaurant } from "./RestaurantContext";
import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { useAuth } from "./AuthContext";
import { APP_SCREENS, useApp } from "./AppContext";

const turf = require("@turf/turf");

const AddressCreateContext = createContext(undefined);

export const DELIVERY_METHODS = {
    PICKUP : 1,
    DELIVERY : 2,
    EAT_IN : 3,
}

export const AddressCreateProvider = ({children}) => {
    //HOOKS
    const { deliveryAreas, restaurantLocation } = useRestaurant();
    const { postRequest } = useServer();
    const { checkAuth, user } = useAuth();
    const { changeAppScreen } = useApp();

    //STATES
    const [addressEditId, setAddressEditId] = useState("");
    const [addressEditMode, setAddressEditMode] = useState(false);
    const [addressDeleteLoading, setAddressDeleteLoading] = useState(false);

    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [selectedDeliveryArea, setSelectedDeliveryArea] = useState(null);

    const [apartment, setApartment] = useState("");
    const [floor, setFloor] = useState("");
    const [entrance, setEntrance] = useState("");
    const [details, setDetails] = useState("");
    const [detailsLoading, setDetailsLoading] = useState(false);

    const [mainAddressLoading, setMainAddressLoading] = useState(false);

    const [globalCurrentAddress, setGlobalCurrentAddress] = useState(null);

    //FUNCTIONS
    const postChanges = () => {
        if (addressEditMode) {
            postUpdate();
        } else {
            postCreate();
        }
    }

    const postCreate = () => {
        if (detailsLoading) {
            return;
        }

        setDetailsLoading(true);

        const data = {
            address,
            latitude,
            longitude,
            apartment,
            floor,
            entrance,
            details
        }

        postRequest(endpoints.delivery_address_create, data, (res) => {
            setDetailsLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    checkAuth();
                    changeAppScreen(APP_SCREENS.DELIVERY_METHODS);
                    clearValues();
                }
            }
        });
    }

    const postUpdate = () => {
        if (detailsLoading) {
            return;
        }

        setDetailsLoading(true);

        const data = {
            id: addressEditId,
            address,
            latitude,
            longitude,
            apartment,
            floor,
            entrance,
            details
        }

        postRequest(endpoints.delivery_address_update, data, (res) => {
            setDetailsLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    checkAuth();
                    changeAppScreen(APP_SCREENS.DELIVERY_METHODS);
                    clearValues();
                }
            }
        });
    }

    const postDelete = () => {
        if (addressDeleteLoading) {
            return;
        }

        setAddressDeleteLoading(true);
        const data = {
            id: addressEditId,
        }

        postRequest(endpoints.delivery_address_delete, data, (res) => {
            setAddressDeleteLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    checkAuth();
                    changeAppScreen(APP_SCREENS.DELIVERY_METHODS);
                    clearValues();
                }
            }
        });
    }

    const clearValues = () => {
        setAddress("");
        setLatitude(0);
        setLongitude(0);
        setSelectedDeliveryArea(null);
        setApartment("");
        setFloor("");
        setEntrance("");
        setDetails("");
        setAddressEditId("");
        setAddressEditMode(false);
    }

    const checkCoverage = (latitude, longitude) => {
        let selectedArea = null;

        for (let i = 0; i < deliveryAreas.length; i++) {
            if (inArea(deliveryAreas[i], latitude, longitude)) {
                selectedArea = deliveryAreas[i];
                break;
            }
        }

        setSelectedDeliveryArea(selectedArea);

        if (selectedArea !== null) {
            setLatitude(latitude);
            setLongitude(longitude);
        }
        
        return selectedArea;
    }

    const checkCoverageOutside = (latitude, longitude) => {
        let selectedArea = null;

        for (let i = 0; i < deliveryAreas.length; i++) {
            if (inArea(deliveryAreas[i], latitude, longitude)) {
                selectedArea = deliveryAreas[i];
                break;
            }
        }
        
        return selectedArea;
    }

    const inArea = (area, lat, long) => {
        if (!area.enable) {
            return false;
        }

        if (area.type === 0) {
            let distance = distanceCoordinates(parseFloat(restaurantLocation.latitude), lat, parseFloat(restaurantLocation.longitude), long);
            let coverage = parseFloat(area.raw_content) * 1609.34;
            return distance <= coverage;
        } else if (area.type === 1) {
            let coordinates = area.raw_content.split(",");
            let Polygon = [];

            for (let i = 0; i < coordinates.length; i++) {
                let value = coordinates[i].split(" ");
                Polygon.push([parseFloat(value[0]), parseFloat(value[1])]);
            }

            Polygon.push(Polygon[0]);

            let point = turf.point([lat, long]);
            let PolygonT = turf.polygon([Polygon], {name: "poly1"});

            return turf.booleanPointInPolygon(point, PolygonT);
        }
    }

    const distanceCoordinates = (lat1, lat2, long1, long2) => {
        const R = 6371e3;

        const rad1 = lat1 * Math.PI /180;
        const rad2 = lat2 * Math.PI /180;

        const deltalat = (lat2 - lat1) * Math.PI / 180;
        const detlalon = (long2 - long1) * Math.PI / 180;

        const a = Math.sin(deltalat / 2) * Math.sin(deltalat / 2) + Math.cos(rad1) * Math.cos(rad2) * Math.sin(detlalon / 2) * Math.sin(detlalon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return R * c;
    }

    const openAddressToEdit = (addressEdit) => {
        setAddressEditMode(true);

        setAddress(addressEdit.address);
        setLatitude(parseFloat(addressEdit.latitude));
        setLongitude(parseFloat(addressEdit.longitude));
        checkCoverage(parseFloat(addressEdit.latitude), parseFloat(addressEdit.longitude));
        setApartment(addressEdit.apartment);
        setFloor(addressEdit.floor);
        setEntrance(addressEdit.entrance);
        setDetails(addressEdit.details);
        setAddressEditId(addressEdit.id);

        changeAppScreen(APP_SCREENS.DELIVERY_ADD_ADDRESS);
    }

    const openAddressCreate = () => {
        setAddressEditMode(false);
        clearValues();
        changeAppScreen(APP_SCREENS.DELIVERY_ADD_ADDRESS);
    }

    const openDeleteAddress = () => {
        changeAppScreen(APP_SCREENS.DELIVERY_ADDRESS_DELETE);
    }

    const setMainAddress = (id) => {
        if (mainAddressLoading) {
            return;
        }

        setMainAddressLoading(true);

        const data = {
            main_address : id,
            main_delivery_method : DELIVERY_METHODS.DELIVERY,
        }

        postRequest(endpoints.delivery_main_set, data, (res) => {
            setMainAddressLoading(false);
            checkAuth();
        });
    }

    const setPickupMain = () => {
        if (mainAddressLoading) {
            return;
        }

        setMainAddressLoading(true);

        const data = {
            main_delivery_method : DELIVERY_METHODS.PICKUP,
        }

        postRequest(endpoints.delivery_main_set, data, (res) => {
            setMainAddressLoading(false);
            checkAuth();
        });
    }

    //EFFECTS
    useEffect(() => {
        if (restaurantLocation == null || deliveryAreas == null)
        {
            return;
        }

        if (user !== null && user.delivery.main_delivery_method == DELIVERY_METHODS.DELIVERY) {
            const address = user.delivery.addresses.find(address => address.id === user.delivery.main_address) || null;

            if (address !== null) {
                const area = checkCoverageOutside(address.latitude, address.longitude);

                let currentGlobalAddress = {
                    address : address,
                    area: area,
                }

                setGlobalCurrentAddress(currentGlobalAddress);
            } else {
                setGlobalCurrentAddress(null);
            }
        } else {
            setGlobalCurrentAddress(null);
        }
    }, [user, restaurantLocation, deliveryAreas]);

    const values = {
        address,
        setAddress,
        checkCoverage,
        selectedDeliveryArea,
        setSelectedDeliveryArea,
        apartment,
        setApartment,
        floor,
        setFloor,
        entrance,
        setEntrance,
        details,
        setDetails,
        detailsLoading,
        setDetailsLoading,
        postChanges,
        checkCoverageOutside,
        addressEditMode,
        setAddressEditMode,
        openAddressToEdit,
        openAddressCreate,
        openDeleteAddress,
        addressDeleteLoading,
        postDelete,
        setMainAddress,
        mainAddressLoading,
        setPickupMain,
        globalCurrentAddress,
    }

    return (
        <AddressCreateContext.Provider
            value={values}
        >
            {children}
        </AddressCreateContext.Provider>
    );
}

export const useAddressCreate = () => useContext(AddressCreateContext);