import './delivery_address_item.css';
import { DeliveryIcon } from '../../icons/DeliveryIcon';
import { DELIVERY_METHODS, useAddressCreate } from '../../context/AddressCreateContext';
import { useEffect, useState } from 'react';
import { PencilIcon } from '../../icons/PencilIcon';
import { useAuth } from '../../context/AuthContext';
import { useRestaurant } from '../../context/RestaurantContext';

export const DeliveryAddressItem = ({address}) => {
    //HOOKS
    const { user } = useAuth();
    const { 
        checkCoverageOutside,
        addressEditMode,
        openAddressToEdit,
        setMainAddress
    } = useAddressCreate();
    const { deliveryAreas } = useRestaurant();

    //STATES
    const [addressError, setAddressError] = useState("");

    //FUNCTIONS
    const setMain = () => {
        if (!(user && user.delivery.main_delivery_method === DELIVERY_METHODS.DELIVERY && user.delivery.main_address === address.id)) {
            setMainAddress(address.id);
        }
    }

    //EFFECTS
    useEffect(() => {
        if (deliveryAreas === null) {
            return;
        } 
        
        const coverage = checkCoverageOutside(parseFloat(address.latitude), parseFloat(address.longitude));
        if (coverage === null) {
            setAddressError("We're sorry, but delivery is not available for this address at the moment.");
        }
    }, [address, deliveryAreas]);

    return (
        <div className='delivery_item' onClick={setMain}>
            <div className='icon'>
                <DeliveryIcon />
            </div>
            <span>
                {address.address}
                <br />
                <span>
                    {addressError}
                </span>
            </span>
            {
                !addressEditMode && (
                    <input type='radio' checked={user && user.delivery.main_delivery_method === DELIVERY_METHODS.DELIVERY && user.delivery.main_address === address.id} />
                )
            }
            {
                addressEditMode && (
                    <button className='light_btn edit_btn' onClick={() => openAddressToEdit(address)}>
                        <PencilIcon />
                    </button>
                )
            } 
        </div>
    );
}