import './modal_header.css';
import { GoBackIcon } from '../../icons/GoBackIcon';
import { useApp } from '../../context/AppContext';
import { BarLoader } from '../BarLoader/BarLoader';

export const ModalHeader = ({title, loading}) => {
    //HOOKS
    const { GoToPrevius } = useApp();

    return (
        <div className='modal_header'>
            <button onClick={GoToPrevius} disabled={loading}>
                <GoBackIcon />
            </button>
            <h2>
                {title}
            </h2>

            {
                loading && (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>
    );
}