import { createContext, useContext, useState } from "react";

const LoginContext = createContext(undefined);

export const LoginScreen = {
    MAIN_SCREEN : 0,
    LOGIN : 1,
    FORGOT_PASSWORD: 2,
    RECOVER_CODE: 3,
    PASSWORD_RESET: 4,
    REGISTER: 5,
}

export const LoginProvider = ({children}) => {
    //STATES
    const [ActiveScreen, setActiveScreen] = useState(LoginScreen.MAIN_SCREEN);
    const [recoverEmail, setRecoverEmail] = useState("");
    const [recoverCode, setRecoverCode] = useState("");

    const values = {
        ActiveScreen,
        setActiveScreen,
        recoverEmail,
        setRecoverEmail,
        recoverCode,
        setRecoverCode,
    }

    return (
        <LoginContext.Provider
            value={values}
        >
            {children}
        </LoginContext.Provider>
    );
}

export const useLogin = () => useContext(LoginContext);