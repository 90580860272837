import './delivery_methods_menu.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { DeliveryAddAddressModal } from '../DeliveryAddAddressModal/DeliveryAddAddressModal';
import { PickupItem } from '../PickupItem/PickupItem';
import { AddressDetailsModal } from '../AddressDetailsModal/AddressDetailsModal';
import { DeliveryAddressItem } from '../DeliveryAddressItem/DeliveryAddressItem';
import { useAuth } from '../../context/AuthContext';
import { useAddressCreate } from '../../context/AddressCreateContext';
import { DeliveryAddressDeleteModal } from '../DeliveryAddressDeleteModal/DeliveryAddressDeleteModal';
import { BarLoader } from '../BarLoader/BarLoader';

export const DeliveryMethodsMenu = () => {
    //HOOKS
    const { user } = useAuth();
    const { currentAppScreen, changeAppScreen } = useApp();
    const { addressEditMode, setAddressEditMode, openAddressCreate, mainAddressLoading } = useAddressCreate();

    return (
        <>
            <div className={(currentAppScreen === APP_SCREENS.DELIVERY_METHODS) ? 'app_modal active' : 'app_modal'} id='delivery_methods'>
                <ModalHeader title={"Delivery methods"} />

                <div className='delivery_methods_container'>
                    <div className='delivery_methods'>
                        <div className='header'>
                            <span>
                                Delivery methods
                            </span>
                            <button className='light_btn' onClick={() => setAddressEditMode(!addressEditMode)}>
                                {addressEditMode ? "Cancel" : "Edit address"}
                            </button>
                            {
                                mainAddressLoading && (
                                    <div className='loader'>
                                        <BarLoader />
                                    </div>
                                )
                            }
                        </div>
                        <div className='body'>
                            <PickupItem />

                            {
                                user && (
                                    user.delivery.addresses.map((address) => {
                                        return <DeliveryAddressItem address={address} key={"delivery_address_"+address.id} />
                                    })
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className='btn_container'>
                    <button onClick={openAddressCreate} className='light_btn'>
                        Add new address
                    </button>
                </div>
            </div>
            <DeliveryAddAddressModal />
            <AddressDetailsModal />
            <DeliveryAddressDeleteModal />
        </>
    );
}
