import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";

const RestaurantContext = createContext(undefined);

export const RestaurantProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();

    //STATES
    const [restaurantInformation, setRestaurantInformation] = useState(null);
    const [restaurantLocation, setRestaurantLocation] = useState(null);
    const [deliveryAreas, setDeliveryAreas] = useState(null);
    const [pickupSetting, setPickupSettings] = useState(null);
    const [cashSetting, setCashSettings] = useState(null);
    const [stripeSettings, setStripeSettings] = useState(null);

    //FUNCTIONS
    const loadRestaurantInformation = () => {
        getRequest(endpoints.restaurant_information_get, {}, (res) => {
            if (res[0]) {
                res = res[1];
                setRestaurantInformation(res.info);
                setRestaurantLocation(res.location);
            }
        });
    }

    const loadRestaurantConfig = () => {
        getRequest(endpoints.restaurant_config_get, {}, (res) => {
            if (res[0]) {
                res = res[1];
                const sortedAreas = res.areas.slice().sort((a, b) => a.area - b.area);
                setDeliveryAreas(sortedAreas);
                setPickupSettings(res.pickup);
                setCashSettings(res.cash);
                setStripeSettings(res.stripe);
            }
        })
    }

    //EFFECTS
    useEffect(() => {
        loadRestaurantInformation();
        loadRestaurantConfig();
    }, []);
    
    const values = {
        restaurantInformation,
        restaurantLocation,
        deliveryAreas,
        pickupSetting,
        cashSetting,
        stripeSettings,
    }

    return (
        <RestaurantContext.Provider
            value={values}
        >
            {children}
        </RestaurantContext.Provider>
    );
}

export const useRestaurant = () => useContext(RestaurantContext);