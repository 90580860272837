import './saved_card_item.css';
import CreditcardIcon from '../../icons/CreditcardIcon';
import { useAuth } from '../../context/AuthContext';
import { PAYMENT_METHODS, usePaymentMethods } from '../../context/PaymentMethodsContext';
import { useRestaurant } from '../../context/RestaurantContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { TrashBinIcon } from '../../icons/TrashBinIcon';

export const SavedCardItem = ({card, displayMode}) => {
    //HOOKS
    const { user } = useAuth();
    const { stripeSettings } = useRestaurant();
    const { setDefaultCard, cardLoading, cardDeleteMode, deleteSavedCard } = usePaymentMethods();

    return (
        <div className="saved_card_item" onClick={() => { if (!displayMode) setDefaultCard(card) } }>
            <div className="icon">
                <CreditcardIcon />
            </div>

            <span>
                {card.brand} ****** {card.last4}
                <br />
                {
                    stripeSettings && stripeSettings.enable === 0 && (
                        <span>
                            Credit card payments are currently unavailable
                        </span>
                    )
                }
            </span>

            {
                !cardDeleteMode && !displayMode && (
                    <input type="radio" checked={user && (user.payment.default_card === card.id && user.payment.payment_method === PAYMENT_METHODS.STRIPE)} />
                )
            }

            {
                cardDeleteMode && !displayMode && (
                    <button className='edit_btn light_btn' onClick={() => deleteSavedCard(card)}>
                        <TrashBinIcon />
                    </button>
                )
            }

            {
                cardLoading === card.id && (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>
    );
}