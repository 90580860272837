import './appnav.css';
import { CartIcon } from '../../icons/CartIcon';
import { HomeIcon } from '../../icons/HomeIcon';
import { ProfileIcon } from '../../icons/ProfileIcon';
import { ReceiptIcon } from '../../icons/ReceiptIcon';
import { MapPointOrbit } from '../../icons/MapPointOrbit';
import { APP_SCREENS, useApp } from '../../context/AppContext';

export const AppNav = () => {
    //HOOKS
    const { changeAppScreen } = useApp();

    return (
        <>
            <div className='bottom_navbar'>
                <button>
                    <HomeIcon />
                </button>
                <button onClick={() => changeAppScreen(APP_SCREENS.DELIVERY_METHODS)}>
                    <MapPointOrbit />
                </button>
                <button onClick={() => changeAppScreen(APP_SCREENS.ORDERS)}>
                    <ReceiptIcon />
                </button>
                <button onClick={() => changeAppScreen(APP_SCREENS.SHOPPING_CART)}>
                    <CartIcon />
                </button>
                <button onClick={() => changeAppScreen(APP_SCREENS.PROFILE)}>
                    <ProfileIcon />
                </button>
            </div>
        </>
    );
};