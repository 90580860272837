import './shopping_cart_item.css';
import { useEffect, useState } from 'react';
import { useMenu } from '../../context/MenuContext';
import { getMediaFile } from '../../ServerRequest';
import { getLocalePrice } from '../../context/AppContext';
import { PlusIcon } from '../../icons/PlusIcon';
import { MinusIcon } from '../../icons/MinusIcon';
import { TrashBinIcon } from '../../icons/TrashBinIcon';
import { BarLoader } from '../BarLoader/BarLoader';
import { useCart } from '../../context/CartContext';
import { PencilIcon } from '../../icons/PencilIcon';

export const ShoppingCartItem = ({item}) => {
    //HOOKS
    const { getProduct } = useMenu();
    const { itemLoading, openEditItem } = useCart();

    //STATES
    const [product, setProduct] = useState(null);
    const [image, setImage] = useState("");

    //EFFECTS
    useEffect(() => {
        setProduct(getProduct(item.product.id));
    }, [item]);

    useEffect(() => {
        if (product !== null) {
            if (product.image) {
                setImage(getMediaFile(product.image.file_id));
            } else {
                setImage("");
            }
        }
    }, [product]);

    return (
        <div className='shopping_cart_item'>
            {
                image !== "" && (
                    <div className='image'>
                        <img src={image} alt='' />
                    </div>
                )
            }
            <div className='info'>
                <h2>
                    {item.product.name}
                </h2>
                <p>
                    {item.product.description !== "null" && item.product.description}
                </p>
                <span>
                    {getLocalePrice(item.subtotal)}
                </span>
            </div>
            <button className='edit_button' onClick={() => openEditItem(item)}>
                <PencilIcon />
            </button>

            <CartItemQtySelector item={item} />
            {
                itemLoading === item.id && (
                    <div className='item_loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>
    );
}

export const CartItemQtySelector = ({item}) => {
    //HOOKS
    const { decreaseItemQty, increaseItemQty } = useCart();

    return (
        <div className='cart_item_qty'>
            <button className={item.qty === 1 ? "trash" : ""} onClick={() => decreaseItemQty(item)}>
                {
                    item.qty === 1 ? <TrashBinIcon /> : <MinusIcon />
                }
            </button>
            <div>
                {item.qty}
            </div>
            <button onClick={() => increaseItemQty(item)}>
                <PlusIcon />
            </button>
        </div>
    );
}