import axios from "axios";
import { createContext, useContext } from "react";
import { serverEnv } from "../env";
import { useAuth } from "./AuthContext";

const ServerContext = createContext(undefined);

export const ServerProvider = ({children}) => {
    //HOOKS
    const api = axios.create({
        baseURL: serverEnv.backendUrl,
        withCredentials: true,
        headers : {
            'X-Mobile-App-Rest' : true,
        },
    });

    const { getAuthToken } = useAuth();

    //FUNCTIONS
    const getRequest = (url, data, callback) => {
        let requestOptions =  {
            params: data,
            headers: {
                'X-Auth-Token': getAuthToken()
            }
        };

        api.get(url, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            callback([false, res]);
        });
    }

    const getRequestParams = (url, data, params, callback) => {
        url = buildURIParams(url, params);
        getRequest(url, data, callback);
    }

    const postRequest = (url, data, callback) => {
        let requestOptions =  {
            headers: {
                'X-Auth-Token': getAuthToken()
            }
        };

        api.post(url, data, requestOptions).then(res => {
            callback([true, res.data]);
        }).catch((res) => {
            callback([false, res]);
        });
    }

    const buildURIParams = (route, params) => {
        let uri = route;
        for (let i = 0; i < params.length; i++) {
            uri = uri.replace(((i + 1) * - 1 ).toString(), params[i]);
        }
        return uri;
    }

    const values = {
        getRequest,
        postRequest,
        getRequestParams
    }

    return (
        <ServerContext.Provider
            value={values}
        >
            {children}
        </ServerContext.Provider>
    );
}

export const useServer = () => useContext(ServerContext);