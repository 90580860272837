import './checkout_modal.css';
import { APP_SCREENS, getLocalePrice, useApp } from "../../context/AppContext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { useCart } from '../../context/CartContext';
import { useEffect, useState } from 'react';
import { CheckoutDelivery } from '../CheckoutDelivery/CheckoutDelivery';
import { CheckoutOrderItems } from '../CheckoutOrderItems/CheckoutOrderItems';
import { CheckoutPaymentMethod } from '../CheckoutPaymentMethod/CheckoutPaymentMethod';
import { CheckoutContactInformation } from '../CheckoutContactInformation/CheckoutContactInformation';
import { CheckoutTip } from '../CheckoutTip/CheckoutTip';
import { CheckoutPriceSummary } from '../CheckoutPriceSummary/CheckoutPriceSummary';
import { useAddressCreate } from '../../context/AddressCreateContext';
import { useCheckout } from '../../context/ChekoutContext';
import { CheckoutPoints } from '../CheckoutPoints/CheckoutPoints';

export const CheckoutModal = () => {
    //HOOKS
    const { currentAppScreen } = useApp();
    const { getCart, cartLoading } = useCart();
    const { checkout, checkoutLoading, total } = useCheckout();

    //STATES
    

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.CHECKOUT) {
            getCart();
        }
    }, [currentAppScreen]);

    

    return (
        <div className={(currentAppScreen === APP_SCREENS.CHECKOUT) ? 'app_modal active' : 'app_modal'} id="checkout_modal">
            <ModalHeader title={"Checkout"} />

            <div className="checkout_body">
                <CheckoutDelivery />   
                <CheckoutOrderItems />
                <CheckoutPaymentMethod />
                <CheckoutPoints />
                <CheckoutContactInformation />
                <CheckoutTip />
                <CheckoutPriceSummary />
            </div>

            <div className="checkout_footer">
                <h2>
                    Total
                    <span>
                        {getLocalePrice(total)}
                    </span>
                </h2>
                <button className='light_btn' disabled={cartLoading || checkoutLoading} onClick={checkout}>
                    Order now
                </button>
            </div>
        </div>
    );
}
