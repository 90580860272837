import { useEffect, useState } from "react"
import { useServer } from "../../context/ServerContext";
import { endpoints } from "../../env";

export function Logo({className}) {
    //HOOKS
    const { getRequest } = useServer();

    const [logo, setLogo] = useState("");

    useEffect(() => {
        getRequest(endpoints.get_logo, {}, (res) => {
            if (res[0]) {
                setLogo(res[1].logo);
            }
        });
    }, [logo]);

    return <img className={className} src={logo} alt="" />
}