import './product_option.css';
import { PRODUCT_OPTIONS, useSingleProduct } from '../../context/SingleProductContext';
import { ProductOptionSelection } from './ProductOptionSelection';
import { ProductOptionExtra } from './ProductOptionExtra';

export const ProductOption = ({option}) => {
    //HOOKS
    const { currentProductOptions } = useSingleProduct();

    return (
        <div className='product_option' id={'product_option_' + option.id}>
            <div className='header'>
                <h2>
                    {option.name}
                </h2>
                {
                    option.type === PRODUCT_OPTIONS.EXTRA && option.limit && (
                        <span className='choice_limit'>
                            Choose up to {option.limit.max} options.
                        </span>
                    )
                }
                
                <span className='selection_error'>
                    {
                        currentProductOptions && currentProductOptions["option_" + option.id].error
                    }
                </span>
            </div>
            {
                option.type === PRODUCT_OPTIONS.SELECTION && (
                    option.choices.map((choice) => {
                        return <ProductOptionSelection choice={choice} key={'choice_' + choice.id} />
                    })
                )
            }  
            {
                option.type === PRODUCT_OPTIONS.EXTRA && (
                    option.choices.map((choice) => {
                        return <ProductOptionExtra choice={choice} key={'choice_' + choice.id} />
                    })
                )
            } 
        </div>
    );
}