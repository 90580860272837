import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { endpoints, serverEnv } from "../env";
import { useCookie } from "./CookieContext";

const AuthContext = createContext(undefined);

export const AuthProvider = ({children}) => {
    //HOOKS
    const api = axios.create({
        baseURL: serverEnv.backendUrl,
        withCredentials: true,
        headers: { "X-Mobile-App-Rest" : true },
    });

    const { getCookie, setCookie } = useCookie();

    //STATES
    const [user, setUser] = useState(null);
    const [isAuth, setIsAuth] = useState(null);

    //FUNCTIONS
    const getAuthToken = () => {
        return getCookie("auth_token");
    }

    const setAuthToken = (token) => {
        setCookie("auth_token", token);
    }

    const checkAuth = () => {
        const token = getAuthToken();

        api.post(endpoints.auth_check, {}, { headers: { "X-Auth-Token" : token } }).then((res) => {
            res = res.data;

            if (res.success) {
                setIsAuth(true);
                setUser(res.user);
            } else {
                setIsAuth(false);
            }
        }).catch((res) => {
        });
    }
    
    const login = (email, password, callback) => {
        const data = {
            email,
            password,
        }

        api.post(endpoints.login, data).then((res) => {
            res = res.data;

            if (res.success) {
                setAuthToken(res.session.token);
                checkAuth();
            }

            callback([true, res]);
        }).catch((res) => {
            callback([false, null]);
        });
    }

    const logout = () => {
        const token = getAuthToken();

        api.post(endpoints.user_logout, {}, { headers: { "X-Auth-Token" : token } }).then((res) => {
            setAuthToken("");
            setIsAuth(false);
            setUser(null);
        }).catch((res) => {

        });
    }

    const getUserDeliveryAddress = (id) => {
        if (user === null) {
            return setTimeout(() => {
                getUserDeliveryAddress(id);
            }, 200);
        }

        return user.delivery.addresses.find(address => address.id === id) || null;
    }

    //EFFECTS
    useEffect(() => {
        checkAuth();    
    }, []);

    const values = {
        user,
        isAuth,
        checkAuth,
        getAuthToken,
        login,
        setAuthToken,
        logout,
        getUserDeliveryAddress,
    }

    return (
        <AuthContext.Provider
            value={values}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);