import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { useSingleProduct } from "./SingleProductContext";

const CartContext = createContext(undefined);

export const CartProvider = ({children}) => {
    //HOOKS
    const { getRequest, postRequest } = useServer();
    const { openProductEdit } = useSingleProduct();

    //STATES
    const [cart, setCart] = useState(null);
    const [cartLoading, setCartLoading] = useState(false);
    const [itemLoading, setItemLoading] = useState("");

    //FUNCTIONS
    const getCart = () => {
        if (cartLoading) {
            return;
        }

        setCartLoading(true);

        getRequest(endpoints.get_cart, {}, (res) => {
            setCartLoading(false);
            if (res[0]) {
                res = res[1];
                setCart(res.cart);
            }
        })
    }

    const decreaseItemQty = (item) => {
        if (itemLoading !== "" || cartLoading) {
            return;
        }

        setItemLoading(item.id);
        setCartLoading(true);

        if (item.qty > 1) {
            postRequest(endpoints.cart_item_update, { id: item.id, qty: item.qty - 1}, (res) => {
                setItemLoading("");
                setCartLoading(false);
                if (res[0]) {
                    res = res[1];

                    if (res.success) {
                        setCart(res.cart);
                    }
                }
            });
        } else {
            postRequest(endpoints.remove_cart_item, { id: item.id }, (res) => {
                setItemLoading("");
                setCartLoading(false);
                if (res[0]) {
                    res = res[1];

                    if (res.success) {
                        setCart(res.cart);
                    }
                }
            });
        }
    }

    const increaseItemQty = (item) => {
        if (itemLoading !== "" || cartLoading) {
            return;
        }

        setItemLoading(item.id);
        setCartLoading(true);

        postRequest(endpoints.cart_item_update, { id: item.id, qty: item.qty + 1}, (res) => {
            setItemLoading("");
            setCartLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    setCart(res.cart);
                }
            }
        });
    }

    const openEditItem = (item) => {
        openProductEdit(item);
    }

    //EFFECTS
    useEffect(() => {
        getCart();
    }, []);

    const values = {
        cart,
        getCart,
        cartLoading,
        itemLoading,
        decreaseItemQty,
        increaseItemQty,
        openEditItem,
    }

    return (
        <CartContext.Provider
            value={values}
        >
            {children}
        </CartContext.Provider>
    );
}

export const useCart = () => useContext(CartContext);