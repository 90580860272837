import './checkout_order_items.css';
import { APP_SCREENS, getLocalePrice, useApp } from "../../context/AppContext";
import { useCart } from "../../context/CartContext";

export const CheckoutOrderItems = () => {
    //HOOKS
    const { changeAppScreen } = useApp();
    const { cart } = useCart();

    return (
        <div className='checkout_order_items'>
            <div className='info'>
                <h2>
                    Your order
                </h2>
                <button onClick={() => changeAppScreen(APP_SCREENS.SHOPPING_CART)}>
                    Modify order
                </button>
            </div>
            <div className='product_container'>
                {
                    cart && cart.items.items.map((item) => {
                        return (
                            <div className='item' key={"checkout_product_item_" + item.id}>
                                <div>
                                    <h2>
                                        {item.qty}x {item.product.name}
                                    </h2>
                                </div>
                                <div>
                                    <span>
                                        { getLocalePrice(item.subtotal)}
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    );
}