import { createContext, useContext, useEffect, useState } from "react";
import { SingleProductModal } from "../components/SingleProductModal/SingleProductModal";
import { APP_SCREENS, useApp } from "./AppContext";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { useMenu } from "./MenuContext";

const SingleProductContext = createContext(undefined);

export const PRODUCT_OPTIONS = {
    SELECTION : 1,
    EXTRA: 2,
}

export const SingleProductProvider = ({children}) => {
    //HOOKS
    const { changeAppScreen, currentAppScreen, GoToPrevius } = useApp();
    const { postRequest } = useServer();
    const { getProduct } = useMenu();


    //STATES
    const [editingProduct, setEditingProduct] = useState(false);
    const [cartItem, setCartItem] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [currentProductOptions, setCurrentProductOptions] = useState(null);
    const [singleProductLoading, setSingleProductLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [qty, setQty] = useState(1);
    
    //FUNCTIONS
    const openProduct = (product) => {
        setEditingProduct(false);
        setQty(1);
        setCurrentProduct(product);
        setCartItem(null);
        buildOptionsData(product);
        changeAppScreen(APP_SCREENS.PRODUCT_MODAL);
    }

    const openProductEdit = (item) => {
        const product = getProduct(item.product.id);
        setEditingProduct(true);
        setCartItem(item);
        setCurrentProduct(product);
        buildOptionsData(product);
        fillOptionsCartItem(item);
        changeAppScreen(APP_SCREENS.PRODUCT_MODAL);
    }

    const setSelectedOption = (optionId, choiceId) => {
        setCurrentProductOptions((prevOptions) => {
            const updatedOptions = { ...prevOptions };
            updatedOptions["option_" + optionId].choice = choiceId;
            return updatedOptions;
        });
    }

    const setSelectedExtra = (optionId, choiceId) => {
        setCurrentProductOptions((prevOptions) => {
            const updatedOptions = {...prevOptions};

            if (updatedOptions["option_" + optionId].choices.includes(choiceId)) {
                const index = updatedOptions["option_" + optionId].choices.indexOf(choiceId);
                updatedOptions["option_" + optionId].choices.splice(index, 1);
            } else {
                if (updatedOptions["option_" + optionId].limit !== null) {
                    if (updatedOptions["option_" + optionId].choices.length >= updatedOptions["option_" + optionId].limit.max) {
                        updatedOptions["option_" + optionId].choices.pop();
                    }
                }

                updatedOptions["option_" + optionId].choices.push(choiceId);
            }

            return updatedOptions;
        });
    }

    const increaseQty = () => {
        setQty((prev) => prev + 1);
    }

    const decreaseQty = () => {
        if (qty === 1) {
            return;
        }

        setQty((prev) => {
            if (prev <= 1) {
                return 1;
            }
            return prev - 1;
        });
    }

    const calcTotal = () => {
        let totalPrice = currentProduct.price;

        Object.keys(currentProductOptions).forEach((key) => {
            const option = currentProductOptions[key];
            
            if (option.option.type === PRODUCT_OPTIONS.SELECTION) {
                const choice = option.option.choices.find(choice => choice.id === option.choice) || null;
                if (choice !== null) {
                    totalPrice += choice.price;
                }
            } else if (option.option.type === PRODUCT_OPTIONS.EXTRA) {
                for (let i = 0; i < option.choices.length; i++) {
                    const choice = option.option.choices.find(choice => choice.id === option.choices[i]) || null;
                    if (choice !== null) {
                        totalPrice += choice.price;
                    }
                }
            }
        });
        totalPrice *= qty;
        setTotal(totalPrice);
    }

    const validateInputs = () => {
        let selectionErrors = [];

        Object.keys(currentProductOptions).forEach((key) => {
            const option = currentProductOptions[key];
            
            if (option.option.type === PRODUCT_OPTIONS.SELECTION) {
                const choice = option.option.choices.find(choice => choice.id === option.choice) || null;
                if (choice === null) {
                    selectionErrors.push(option);
                }
            }
        });

        setCurrentProductOptions((prevOptions) => {
            const updatedOptions = {...prevOptions};
            Object.keys(updatedOptions).forEach((key) => {
                updatedOptions[key].error = "";
            });

            for (let i = 0; i < selectionErrors.length; i++) {
                updatedOptions["option_" + selectionErrors[i].option.id].error = "Please choose an option.";
            }

            return updatedOptions;
        });

        if (selectionErrors.length > 0) {
            document.querySelector('#product_option_' + selectionErrors[0].option.id).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return selectionErrors.length === 0;
    }

    const postProduct = () => {
        if (singleProductLoading) {
            return;
        }
        
        setSingleProductLoading(true);

        if(validateInputs()) {
            if (editingProduct) {
                productUpdate();
            } else {
                productAdd();
            }
        } else {
            setSingleProductLoading(false);
        }
    }

    const productAdd = () => {
        const data = getProductData();
        postRequest(endpoints.add_product, data, (res) => {
            setSingleProductLoading(false);
            if (res[0]) {
                res = res[1];
                
                //TODO : BETTER ERROR VALIDATION
                GoToPrevius();
            }
        });
    }

    const productUpdate = () => {
        let data = getProductData(); 
        data.id = cartItem.id;

        postRequest(endpoints.cart_item_update, data, (res) => {
            setSingleProductLoading(false);
            if (res[0]) {
                res = res[1];
                
                //TODO : BETTER ERROR VALIDATION
                GoToPrevius();
            }
        });
    }

    const getProductData = () => {
        let data = {
            product_id: currentProduct.id,
            qty,
            options: {}
        }

        Object.keys(currentProductOptions).forEach((key) => {
            const selectedOption = currentProductOptions[key];
            if (selectedOption.option.type === PRODUCT_OPTIONS.SELECTION) {
                data.options[key] = {
                    choice: selectedOption.choice
                };
            } else if (selectedOption.option.type === PRODUCT_OPTIONS.EXTRA) {
                data.options[key] = {
                    choices: selectedOption.choices
                };
            }
        });

        return data;
    }

    const buildOptionsData = (product) => {
        let options = {};
        product.options.forEach((option) => {
            if (option.type === PRODUCT_OPTIONS.SELECTION) {
                options["option_" + option.id] = {
                    choice: -1,
                    option: option,
                    error: "",
                };
            } else if (option.type === PRODUCT_OPTIONS.EXTRA) {
                options["option_" + option.id] = {
                    choices: [],
                    limit: option.limit ? option.limit : null, 
                    option: option,
                    error: "",
                };
            }
        });
        setCurrentProductOptions(options);
    }

    const fillOptionsCartItem = (item) => {
        setCurrentProductOptions((prevOptions) => {
            const updatedOptions = { ...prevOptions };
            
            for (let i = 0; i < item.options.length; i++) {
                let option = item.options[i];

                if (option.option_db.type === PRODUCT_OPTIONS.SELECTION) {
                    if (option.choices[0]) {
                        updatedOptions["option_" + option.option].choice = option.choices[0].id;
                    }
                } else if (option.option_db.type === PRODUCT_OPTIONS.EXTRA) {
                    let choices = [];

                    for (let j = 0; j < option.choices.length; j++) {
                        choices.push(option.choices[j].id);
                    }

                    if (updatedOptions) {
                        updatedOptions["option_" + option.option].choices = choices;
                    }
                }
            }

            return updatedOptions;
        });
        setQty(item.qty);
    }

    //EFFECTS
    /*useEffect(() => {
        if (currentProduct !== null) {
            let options = {};
            currentProduct.options.forEach((option) => {
                if (option.type === PRODUCT_OPTIONS.SELECTION) {
                    options["option_" + option.id] = {
                        choice: -1,
                        option: option,
                        error: "",
                    };
                } else if (option.type === PRODUCT_OPTIONS.EXTRA) {
                    options["option_" + option.id] = {
                        choices: [],
                        limit: option.limit ? option.limit : null, 
                        option: option,
                        error: "",
                    };
                }
            });
            setCurrentProductOptions(options);
        } else {
            setCurrentProductOptions(null);
            setEditingProduct(false);
            setQty(1);
        }
    }, [currentProduct]);*/

    useEffect(() => {
        if (currentAppScreen !== APP_SCREENS.PRODUCT_MODAL && (currentProduct !== null)) {
            setCurrentProduct(null);
            setQty(1);
            setCartItem(null);
            setCurrentProductOptions(null);
        }
    }, [currentAppScreen]);

    useEffect(() => {
        if (currentProduct !== null && currentProductOptions !== null) {
            calcTotal();
        } else {
            setTotal(0);
        }
    }, [currentProductOptions, currentProduct, qty]);

    const values = {
        openProduct,
        currentProduct,
        currentProductOptions,
        setSelectedOption,
        setSelectedExtra,
        singleProductLoading,
        total,
        qty,
        increaseQty,
        decreaseQty,
        postProduct,
        openProductEdit,
        editingProduct,
    }
    
    return (
        <SingleProductContext.Provider
            value={values}
        >
            <SingleProductModal />
            {children}
        </SingleProductContext.Provider>
    );
}

export const useSingleProduct = () => useContext(SingleProductContext);