import './App.css';
import { Route, Routes } from 'react-router-dom';
import { LoginWrapper } from './pages/login/Login';
import { ProtectedRoutes } from './components/ProtectedRoutes/ProtectedRoutes';
import { IndexWrapper } from './pages/index/Index';

function App() {
  	return (
    	<>
			<Routes>
				<Route path='/login' element={<LoginWrapper />} />
			</Routes>

			<ProtectedRoutes>
				<Route path='/' element={<IndexWrapper />}></Route>
			</ProtectedRoutes>
    	</>
  	);
}

export default App;
