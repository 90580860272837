export const serverEnv = {
    //backendUrl: "http://192.168.1.15:8000",
    backendUrl: "https://demoappbackend.ajcreativestudiostemp.com",
    appleToken: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkpWTTU1WVI2OVcifQ.eyJpc3MiOiI1MkZLM01DVTlTIiwiaWF0IjoxNjg5MzQ0MjYzLCJleHAiOjE3MjA3NDI0MDAsIm9yaWdpbiI6IioifQ.EwOe3A1WM-n71JmbncnDZwp4Y19XHXlfwkk2xTmvHVf3F5HDzYZS2B3l55GVTAhszIG5u00SNSVZiN-Ypgx2mg",
}

export const endpoints = {
    get_logo: '/application/system/api/logo/geturl',
    login: '/api/v2/restaurant/users/login',
    guest_login: '/api/v2/restaurant/users/guest/register',
    register: '/api/v2/restaurant/users/register',
    send_recover: '/application/system/auth/user/forgot/send',
    recover_code_check: '/application/system/auth/user/forgot/recover/code/check',
    recover_password: '/application/system/auth/user/forgot/recover',
    auth_check: '/api/v2/restaurant/users/auth/check',
    user_logout: '/api/v2/restaurant/users/logout',
    user_update: '/api/v2/restaurant/users/update',
    restaurant_information_get: '/api/public/v1/restaurant/info/get',
    restaurant_config_get: '/api/public/v1/restaurant/order/config/get',
    menu_get: '/api/public/v1/menu/-1/product/list',
    delivery_address_create: '/api/v2/restaurant/users/address/create',
    delivery_address_update: '/api/v2/restaurant/users/address/update',
    delivery_address_delete: '/api/v2/restaurant/users/address/delete',
    delivery_main_set: '/api/v2/restaurant/users/address/setmain',
    add_product: '/api/v2/restaurant/cart/item/add',
    get_cart: '/api/v2/restaurant/cart/get',
    cart_item_update: '/api/v2/restaurant/cart/item/update',
    remove_cart_item: '/api/v2/restaurant/cart/delete',

    setup_intent_get: '/api/v2/stripe/setup/intent',
    load_saved_cards: '/api/v2/restaurant/payments/saved_cards/get',
    set_default_card: '/api/v2/restaurant/payments/saved_cars/set/default',
    set_default_cash: '/api/v2/restaurant/payments/set/default/cash',
    delete_saved_card: '/api/v2/restaurant/payments/saved_cards/delete',
    get_orders: '/api/v2/restaurant/users/orders/get',
    chekout: '/api/v2/order/checkout',

    user_get: '/application/system/auth/v2/user/get',
    file_get: 'api/public/v1/media/img/-1/get',
    add_cart: '/cart/product/-1/add',
    update_cart_item_info: '/cart/product/-1/update/info',
    cart_empty: '/cart/empty',
    cart_product_update: '/cart/product/-1/update',
    coupon_add: '/cart/coupon/add',
    product_get: '/api/public/v1/menu/product/-1/get',
    creditcards_list: '/application/system/stripe/card/list',
    creditcards_delete: '/application/system/stripe/card/bulk/delete',
}