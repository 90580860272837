import { createContext, useContext } from "react";
import Cookies from "universal-cookie";

const CookieContext = createContext(undefined);

export const CookieProvider = ({children}) => {
    //FUNCTIONS
    const getCookie = (cname) => {
        const cookies = new Cookies();
        return cookies.get(cname);
    }

    const setCookie = (cname, cvalue) => {
        const cookies = new Cookies();
        let d = new Date();
        d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

        cookies.set(cname, cvalue, {
            expires: d,
        });
    };

    const values = {
        getCookie,
        setCookie,
    }

    return (
        <CookieContext.Provider
            value={values}
        >
            {children}
        </CookieContext.Provider>
    );
}

export const useCookie = () => useContext(CookieContext);