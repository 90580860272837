import './personal_information_modal.css';
import { APP_SCREENS, useApp } from "../../context/AppContext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { endpoints } from '../../env';
import { useServer } from '../../context/ServerContext';

export const PersonalInformationModal = () => {
    //HOOKS
    const { currentAppScreen } = useApp();
    const { user, checkAuth } = useAuth();
    const { postRequest } = useServer();

    //STATES
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [loading, setLoading] = useState(false);

    const [nameError, setNameError] = useState(""); 

    //FUNCTIONS
    const update = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setNameError("");

        const data = {
            name,
            phone,
        }

        postRequest(endpoints.user_update, data, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                if (!res.success && res.error_code === "validator_exception" && res.errors.name) {
                    setNameError("The name is required");
                } else {
                    checkAuth();
                }
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.PERSONAL_INFORMATION) {
            setName(user.contact.name);
            setPhone(user.contact.phone);
            setEmail(user.contact.email);
            setBirthdate(user.contact.birthdate);
        }
    }, [currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.PERSONAL_INFORMATION) ? 'app_modal active' : 'app_modal'} id='personal_information_modal'>
            <ModalHeader title={"Personal information"} />
            <div className='personal_information_body'>
                <div className='container'>
                    <div className='header'>
                        <h2>
                            Update your personal information
                        </h2>

                        {
                            loading && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </div>
                    <div className='body'>
                        <div className='input_container'>
                            <label>Name</label>
                            <input type='text' className='primary_input' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' />
                            <span>
                                {nameError}
                            </span>
                        </div>
                        <div className='input_container'>
                            <label>Phone</label>
                            <input type='text' className='primary_input' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone' />
                        </div>
                        <div className='input_container'>
                            <label>Email</label>
                            <input type='text' className='primary_input' value={email} onChange={(e) => setEmail(e.target.value)} disabled placeholder='Email' />
                        </div>
                        <div className='input_container'>
                            <label>Birthdate</label>
                            <input type='date' className='primary_input' value={birthdate} onChange={(e) => setBirthdate(e.target.value)} disabled placeholder='Email' />
                        </div>
                        <div className='btn_container'>
                            <button className='light_btn' onClick={update} disabled={loading}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}