import './app_header.css';
import ProfilePic from '../../assets/img/nophoto.png';
import { useAuth } from '../../context/AuthContext';
import { Logo } from '../Logo/Logo';
import { APP_SCREENS, useApp } from '../../context/AppContext';

export const AppHeader = ({title}) => {
    //HOOKS
    const { user } = useAuth();
    const { changeAppScreen } = useApp();

    return (
        <>
            <div className='app_header'>
                <div className='main_header'>
                    <div className='logo'>
                        <Logo />
                    </div>
                    <div className='profile' onClick={() => changeAppScreen(APP_SCREENS.PROFILE)}>
                        <img src={ProfilePic} alt='' />
                        <h2>
                            My profile
                        </h2>
                    </div>
                </div>

                <div className='information_header'>
                    <div className='name_container'>
                        <h2>
                            Hi, { user && user.contact.name }
                        </h2>
                    </div>
                    <div className='points_container'>
                        <div className='points'>
                            <span>
                                My points 
                            </span>
                            <h2>
                                {user && user.points.points}
                            </h2>
                            <button className='about_points_btn'>
                                <i className="fa-regular fa-circle-question"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};