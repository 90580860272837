import './single_product_modal.css';
import { APP_SCREENS, getLocalePrice, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { useEffect, useState } from 'react';
import { getMediaFile } from '../../ServerRequest';
import { ProductOption } from '../ProductOption/ProductOption';
import { BarLoader } from '../BarLoader/BarLoader';
import { useSingleProduct } from '../../context/SingleProductContext';
import { PlusIcon } from '../../icons/PlusIcon';
import { MinusIcon } from '../../icons/MinusIcon';

export const SingleProductModal = () => {
    //HOOKS
    const { currentAppScreen } = useApp();
    const {
        currentProduct,
        singleProductLoading,
        total,
        postProduct,
        editingProduct,
    } = useSingleProduct();

    //STATES
    const [image, setImage] = useState("");

    //EFFECTS
    useEffect(() => {
        if (currentProduct !== null) {
            if (currentProduct.image) {
                setImage(getMediaFile(currentProduct.image.file_id));
            } else {
                setImage("");
            }
        } else {
            setImage("");
        }
    }, [currentProduct]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.PRODUCT_MODAL) ? 'app_modal active' : 'app_modal'} id='single_product_modal'>
            <ModalHeader title={currentProduct ? currentProduct.name : ""} />
            <div className='single_product_body'>
                {
                    image !== "" && (
                        <div className='image'>
                            <img src={image} alt='' />
                        </div>
                    )
                }
                <h2 className='title'>
                    {currentProduct && currentProduct.name}
                </h2>
                {
                    currentProduct && currentProduct.description !== "null" && (
                        <p className='description'>
                            {currentProduct.description}
                        </p>
                    )
                }
                <h2 className='price'>
                    ${currentProduct && currentProduct.price}
                </h2>

                {
                    currentProduct && currentProduct.options.map((option) => {
                        return <ProductOption option={option} key={'product_' + currentProduct.id + "_option_" + option.id} />
                    })
                }
            </div>
            <div className='single_product_footer'>
                {
                    singleProductLoading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                <div>
                    <QtySelector />
                </div>
                <div>
                    <button className='light_btn' onClick={postProduct} disabled={singleProductLoading}>
                        {editingProduct ? "Update" : "Add to cart"} {getLocalePrice(total)}
                    </button>
                </div>
            </div>
        </div>
    );
}

export const QtySelector = () => {
    //HOOKS
    const { qty, increaseQty, decreaseQty } = useSingleProduct();
    
    return (
        <div className='qty_selector'>
            <button onClick={increaseQty}>
                <PlusIcon />
            </button>
            <div> 
                {qty }
            </div>
            <button className='right' onClick={decreaseQty}>
                <MinusIcon />
            </button>
        </div>
    );
}