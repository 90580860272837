import './checkout_loading_modal.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { SpinnerLoader } from '../SpinnerLoader/SpinnerLoader';
import { CheckoutPaymentMethod } from '../CheckoutPaymentMethod/CheckoutPaymentMethod';
import { CheckoutDelivery } from '../CheckoutDelivery/CheckoutDelivery';
import { useCheckout } from '../../context/ChekoutContext';

export const CheckoutLoadingModal = () => {
    //HOOKS
    const { currentAppScreen, GoToPrevius, ResetAndGo } = useApp();
    const { checkoutResult } = useCheckout();

    return (
        <div className={(currentAppScreen === APP_SCREENS.CHECKOUT_LOADING) ? 'app_modal active' : 'app_modal'} id='checkout_loading_modal'>
            <div className='header'>
                {
                    checkoutResult === null && (
                        <h2>
                            We're processing your order
                        </h2>
                    )
                }
                {
                    checkoutResult && checkoutResult.success && (
                        <h2 className='fullwith'>
                            We're preparing your order
                        </h2>
                    )
                }
                {
                    checkoutResult && !checkoutResult.success && (
                        <h2 className='fullwith'>
                            Something went wrong with your order
                        </h2>
                    )
                }
                {
                    checkoutResult === null && (
                        <SpinnerLoader />
                    )
                }
            </div>
            {
                checkoutResult && !checkoutResult.success && (
                    <p>
                        {OrderErrorsEnum[checkoutResult.error_code] ?? "Something went wrong, please try again in a bit."}
                    </p>
                )
            }
            <CheckoutDelivery nochange={true} />
            <CheckoutPaymentMethod nochange={true} />

            <div className='actions'>
                {
                    checkoutResult && !checkoutResult.success && (
                        <button className='light_btn' onClick={GoToPrevius}>
                            Go back
                        </button>
                    )
                }

                {
                    checkoutResult && checkoutResult.success && (
                        <button className='light_btn success' onClick={ () => ResetAndGo(APP_SCREENS.ORDERS)}>
                            My orders
                        </button>
                    )
                }
            </div>
        </div>
    );
}

const OrderErrorsEnum = {
    "invalid_captcha": "Invalid captcha. Please try again.",
    "restaurant_close": "Sorry, the restaurant is currently closed.",
    "required_fields": "Please fill in all required fields.",
    "empty_cart": "Your cart is empty. Please add items before checking out.",
    "unvailable_products": "Some products in your cart are currently unavailable. Please check your shopping cart",
    "delivery_disabled": "Delivery orders are currently disabled. Please choose another option.",
    "delivery_coverage": "Delivery is not available in your area. Please select a different delivery address.",
    "min_delivery": "Minimum order for delivery not met. Please add more items to meet the minimum order value.",
    "pickup_disabled": "Pickup orders are currently disabled. Please choose another option.",
    "min_pickup": "Minimum order for pickup not met. Please add more items to meet the minimum order value.",
    "cash_disabled": "Cash payments are currently disabled. Please choose another payment method.",
    "stripe_disabled": "Credit card payments are currently disabled. Please choose another payment method.",
    "paypal_disabled": "PayPal payment option is currently disabled. Please choose another payment method.",
    "application_payment_fail": "Your card was declined. Please try again or choose a different payment method."
};