import './checkout_tip.css';
import { useCheckout } from '../../context/ChekoutContext';
import { useState } from 'react';

export const CheckoutTip = () => {
    //HOOKS
    const { tip, setTip } = useCheckout();

    //STATES
    const [showForm, setShowForm] = useState(false);
    

    return (
        <div className={showForm ? "checkout_tip_container active" : "checkout_tip_container"}>
            <div className="info">
                <h2>
                    Leave a tip
                </h2>
                <button onClick={() => setShowForm((prev) => !prev)}>
                    Add tip
                </button>
            </div>
            <div className='form'>
                <div className='input_container'>
                    <label>Tip</label>
                    <input type='number' className='primary_input' placeholder='Tip' value={tip} onChange={(e) => {if (e.target.value < 0) {setTip(0)} else { setTip(e.target.value)} }}/>
                    <span>
                        
                    </span>
                </div>
            </div>
        </div>
    );
}