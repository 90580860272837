import './menu.css';
import { MenuSection } from '../MenuSection/MenuSection';  
import { useMenu } from '../../context/MenuContext';

export const Menu = () => {
    //HOOKS
    const { menu } = useMenu();

    return (
        <>
            <div className='menu_nav'>
                {menu.sections && menu.sections.map((section, index) => {
                    return (
                        <div className='item' key={"manu_nav_section_" + section.id}>
                            {section.name}
                        </div>
                    );
                })}
            </div>
            <div className='menu_container'>
                {menu.sections && menu.sections.map((section) => {
                    return (
                        <MenuSection section={section} key={'menu_section_'+section.id} />
                    );
                })}
            </div>
        </>
    );
};