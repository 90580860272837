import './delivery_address_modal.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { MapPointOrbit } from '../../icons/MapPointOrbit';
import { AppleMapKit } from '../AppleMapKit/AppleMapKit';
import { useEffect, useRef, useState } from 'react';
import { useRestaurant } from '../../context/RestaurantContext';
import { useAddressCreate } from '../../context/AddressCreateContext';

export const DeliveryAddAddressModal = () => {
    //HOOKS
    const { currentAppScreen, changeAppScreen } = useApp();
    const { restaurantLocation, restaurantInformation } = useRestaurant();
    const { 
        address,
        setAddress,
        checkCoverage,
        selectedDeliveryArea,
        setSelectedDeliveryArea,
        addressEditMode,
        openDeleteAddress,
    } = useAddressCreate();
    
    //REFS
    const mapRef = useRef();

    //STATES
    const [userAnnotation, setUserAnnotation] = useState(null);
    const [addressError, setAddressError] = useState("");

    //FUNCTIONS
    const onMapLoad = () => {
        mapRef.current.addAnnotation(parseFloat(restaurantLocation.latitude), parseFloat(restaurantLocation.longitude), restaurantInformation.name, (an) => {
            if (an === false) {
                setTimeout(() => {
                    onMapLoad();
                }, 300);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen !== APP_SCREENS.DELIVERY_ADD_ADDRESS) {
            return;
        }
        
        const delayDebounceFn = setTimeout(() => {
            setAddressError("");
            if (address !== "") {
                mapRef.current.geoCoder(address, (res) => {
                    if (res === false) {
                        setAddressError("We were unable to find the address");

                        setSelectedDeliveryArea(null);
                        if (userAnnotation !== null) {
                            mapRef.current.removeAnnotation(userAnnotation);
                            setUserAnnotation(null);
                            mapRef.current.centerToCoordinates(parseFloat(restaurantLocation.latitude), parseFloat(restaurantLocation.longitude), 13);
                        }
                    } else {
                        if (userAnnotation !== null) {
                            mapRef.current.removeAnnotation(userAnnotation);
                        }

                        mapRef.current.addAnnotation(parseFloat(res.coordinate.latitude), parseFloat(res.coordinate.longitude), "Deliver here", (an) => {
                            setUserAnnotation(an);
                        });

                        mapRef.current.centerToCoordinates(res.coordinate.latitude, res.coordinate.longitude, 13);
                        //CHECKCOVERAGE
                        let area = checkCoverage(res.coordinate.latitude, res.coordinate.longitude);

                        if (area === null) {
                            setAddressError("Sorry, we don´t deliver to this location");
                        }
                    }
                });
            } else {
                setSelectedDeliveryArea(null);

                if (userAnnotation !== null) {
                    mapRef.current.removeAnnotation(userAnnotation);
                    setUserAnnotation(null);
                    mapRef.current.centerToCoordinates(parseFloat(restaurantLocation.latitude), parseFloat(restaurantLocation.longitude), 13);
                }
            } 
        }, 800);

        return () => clearInterval(delayDebounceFn);
    }, [address, currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.DELIVERY_ADD_ADDRESS) ? 'app_modal active' : 'app_modal'} id='add_address_modal'>
            <ModalHeader title='Add new address' />
            <div className='address_add_body'>
                <div className='icon_input'>
                    <MapPointOrbit />
                    <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} />
                </div>
                <span className='error_msg'>
                    {addressError}
                </span>
                <div className='map_container'>
                    {
                        (currentAppScreen === APP_SCREENS.DELIVERY_ADD_ADDRESS && restaurantLocation !== null) && (
                            <AppleMapKit id='add_address_map' lat={parseFloat(restaurantLocation.latitude)} long={parseFloat(restaurantLocation.longitude)} setMapLoadedCallback={onMapLoad} ref={mapRef} />
                        )
                    }
                </div>
            </div>
            {
                addressEditMode && (
                    <div className='btn_container' style={{padding: "0 5%"}}>
                        <button className='secundary_btn' onClick={openDeleteAddress}>
                            Delete address
                        </button>
                    </div>
                )
            }
            {
                selectedDeliveryArea !== null && (
                    <div className='btn_container'>
                        <button className='light_btn' onClick={() => changeAppScreen(APP_SCREENS.DELIVERY_DETAILS)}>
                            {addressEditMode ? 'Update address' : 'Add address'}
                        </button>
                    </div>
                )
            }
        </div>
    );
}