import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";
import { postRequest } from "../ServerRequest";
import { useAuth } from "./AuthContext";

const PaymentMethodsContext = createContext(undefined);

export const PAYMENT_METHODS = {
    CASH : 1,
    STRIPE : 2,
    PAYPAL : 3,
}

export const PaymentMethodsProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();
    const { checkAuth } = useAuth();

    //STATES
    const [addCardLoading, setAddCardLoading] = useState(false);
    const [savedCards, setSavedCards] = useState([]);
    const [loadingCards, setLoadingCards] = useState(false);
    const [cardLoading, setCardLoading] = useState("");
    const [cardDeleteMode, setCardDeleteMode] = useState(false);

    //FUNCTIONS
    const loadSavedCards = () => {
        if (loadingCards) {
            return;
        }

        setLoadingCards(true);

        getRequest(endpoints.load_saved_cards, {}, (res) => {
            setLoadingCards(false);
            if (res[0]) {
                res = res[1];
                setSavedCards(res.cards);
            }
        });
    }

    const setDefaultCard = (card) => {
        if (loadingCards || cardLoading !== "" || cardDeleteMode) {
            return;
        }

        setLoadingCards(true);
        setCardLoading(card.id);

        postRequest(endpoints.set_default_card, { card_id : card.id }, (res) => {
            setLoadingCards(false);
            setCardLoading("");
            if (res[0]) {
                res = res[1];
                checkAuth();
            }
        });
    }

    const setDefaultCash = () => {
        if (loadingCards || cardLoading !== "") {
            return;
        }

        setLoadingCards(true);
        setCardLoading("cash");

        postRequest(endpoints.set_default_cash, {}, (res) => {
            setLoadingCards(false);
            setCardLoading("");
            if (res[0]) {  
                res = res[1];
                checkAuth();
            }
        });
    }

    const deleteSavedCard = (card) => {
        if (loadingCards || cardLoading !== "") {
            return;
        }

        setLoadingCards(true);
        setCardLoading(card.id);

        postRequest(endpoints.delete_saved_card, { card_id : card.id }, (res) => {
            setLoadingCards(false);
            setCardLoading("");
            if (res[0]) {
                res = res[1];
                loadSavedCards();
                checkAuth();
            }
        });
    }

    const getSavedCard = (id) => {
        return savedCards.find(card => card.id === id) || null;
    }

    //EFFECTS
    useEffect(() => {
        loadSavedCards();
    }, []);

    const values = {
        savedCards,
        loadingCards,
        loadSavedCards,
        setDefaultCard,
        cardLoading,
        setDefaultCash,
        cardDeleteMode,
        setCardDeleteMode,
        deleteSavedCard,
        addCardLoading,
        setAddCardLoading,
        getSavedCard,
    }

    return (
        <PaymentMethodsContext.Provider
            value={values}
        >
            {children}
        </PaymentMethodsContext.Provider>
    );
}

export const usePaymentMethods = () => useContext(PaymentMethodsContext);