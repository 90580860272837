import './orders_modal.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { OrderItem } from '../OrderItem/OrderItem';
import { useOrders } from '../../context/OrdersContext';
import { useEffect } from 'react';

export const OrdersModal = () => {
    //HOOKS
    const { currentAppScreen } = useApp();
    const {
        orders,
        ordersLoading,
        loadOrders,
    } = useOrders();

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.ORDERS) {
            loadOrders();
        }
    }, [currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.ORDERS) ? 'app_modal active' : 'app_modal'}>
            <ModalHeader title={"Orders"} loading={ordersLoading} />
            <div className='orders_container'>
                {
                    orders
                        .sort((a, b) => b.id - a.id)
                        .map((order) => {
                            return <OrderItem order={order} key={'order_item_' + order.id} />
                        })
                }
            </div>
        </div>
    );
}