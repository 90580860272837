import './checkout_contact_information.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { useEffect, useState } from 'react';
import { useCheckout } from '../../context/ChekoutContext';

export const CheckoutContactInformation = () => {
    //HOOKS
    const { user } = useAuth();
    const { currentAppScreen } = useApp();
    const {
        name,
        setName,
        email,
        setEmail,
        phone,
        setPhone
    } = useCheckout();

    //STATES
    const [showForm, setShowForm] = useState(false);
    

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen !== APP_SCREENS.CHECKOUT) {
            return;
        }

        if (user !== null) {
            if (name === "") {
                setName(user.contact.name);
            }

            if (email === "") {
                setEmail(user.contact.email);
            }

            if (phone === "") {
                setPhone(user.contact.phone);
            }
        }
    }, [user, currentAppScreen]);

    return (
        <div className={showForm ? 'checkout_contact_information active' : 'checkout_contact_information'}>
            <div className='info'>
                <h2>
                    Contact information
                </h2>
                <button onClick={() => setShowForm((prev) => !prev)}>
                    Add contact information
                </button>
            </div>
            <div className='form'>
                <div className='input_group'>
                    <div className='input_container'>
                        <label>Name</label>
                        <input type='text' className='primary_input' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}/>
                        <span>
                            
                        </span>
                    </div>
                    <div className='input_container'>
                        <label>Phone</label>
                        <input type='text' className='primary_input' placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)}/>
                        <span>
                            
                        </span>
                    </div>
                </div>
                <div className='input_group'>
                    <div className='input_container'>
                        <label>Email</label>
                        <input type='text' className='primary_input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <span>
                            
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}