import './forgot_password.css';
import { useEffect, useRef, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { LoginScreen, useLogin } from '../../pages/login/LoginContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';

export const ForgotPassword = () => {
    //HOOKS
    const { 
        ActiveScreen,
        setActiveScreen,
        recoverEmail,
        setRecoverEmail
    } = useLogin();

    const { postRequest } = useServer();

    //REFS
    const formRef = useRef();

    //STATES
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const postRecover = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");

        const data = {
            email: recoverEmail,
        }

        postRequest(endpoints.send_recover, data, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];

                if (res.success) {
                    setActiveScreen(LoginScreen.RECOVER_CODE);
                } else {
                    setErrorMsg("The email is not associated with any account.");
                }
            } else {
                setErrorMsg("Server error, please try again in a bit.");
            }
        });
    }

    useEffect(() => {
        if (ActiveScreen === LoginScreen.FORGOT_PASSWORD) {
            formRef.current.classList.add('active');
        } else {
            formRef.current.classList.remove('active');
        }
    }, [ActiveScreen]);

    return (
        <div className='forgot_password_modal' ref={formRef}>
            <button className='close' onClick={() => setActiveScreen(LoginScreen.MAIN_SCREEN)}>
                <i className="fa-solid fa-chevron-down"></i>
            </button>
            <h2 className="title">
                Reset your password
            </h2>
            <span className='subtitle'></span>
            <div className='form'>
                <div className='input_group'>
                    <label>Email</label>
                    <input type='text' value={recoverEmail} onChange={(e) => setRecoverEmail(e.target.value)} />
                </div>
                <p className='forgot' onClick={() => setActiveScreen(LoginScreen.LOGIN)} disabled={loading}>
                    Login
                </p>
                {
                    errorMsg !== "" && (
                        <span className='error_msg'>
                            {errorMsg}
                        </span>
                    )
                }
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                <button onClick={postRecover} disabled={loading}>
                    Send reset email
                </button>
            </div>
        </div>
    );
};