import './menu_section.css';
import {MenuProduct } from '../MenuProduct/MenuProduct';

export const MenuSection = (props) => {
    return (
        <div className='menu_category_container'>
            <h2 className='title'>{props.section.name}</h2>
            <div className='class_menu_category_list'>
                {props.section.products && props.section.products.map(product => {
                    return (
                        <MenuProduct key={"menu_product_" + product.id} product={product} />
                    );
                })}
            </div>
        </div>
    );
};