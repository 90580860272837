import { getLocalePrice } from "../../context/AppContext";
import { useSingleProduct } from "../../context/SingleProductContext";

export const ProductOptionExtra = ({choice}) => {
    //HOOKS
    const { currentProductOptions, setSelectedExtra } = useSingleProduct();

    return (
        <div className="product_option_selection" onClick={() => setSelectedExtra(choice.dish_option_id, choice.id)}>
            <div className='info'>
                <h2>{choice.name}</h2>
                {
                    choice.price > 0 && (
                        <span>
                            +{getLocalePrice(choice.price)}
                        </span>
                    )
                }
            </div>
            <div>
                <input type="checkbox" checked={currentProductOptions && currentProductOptions["option_" + choice.dish_option_id].choices.includes(choice.id)} />
            </div>
        </div>
    );
}