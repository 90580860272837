import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import { Logo } from '../../components/Logo/Logo';
import { useEffect, useState } from 'react';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { Blur } from '../../components/Blur/Blur';
import { RegisterForm } from '../../components/RegisterForm/RegisterForm';
import { ForgotPassword } from '../../components/ForgotPassword/ForgotPassword';
import { ResetPassCode } from '../../components/ResetPassCode/ResetPassCode';
import { ResetPassword } from '../../components/ResetPassword/ResetPassword';
import { useAuth } from '../../context/AuthContext';
import { LoginProvider, LoginScreen, useLogin } from './LoginContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';
import { BarLoader } from '../../components/BarLoader/BarLoader';

export const LoginWrapper = () => {
    return (
        <LoginProvider>
            <Login />
        </LoginProvider>
    );
}

export const Login = () => {
    //HOOKS
    const { isAuth, setAuthToken, checkAuth } = useAuth();
    const { setActiveScreen } = useLogin();
    const { postRequest } = useServer();
    const navigate = useNavigate();

    //STATES
    const [guestLoading, setGuestLoading] = useState(false);

    //FUNCTIONS
    const postLoginGuest = () => {
        if (guestLoading) {
            return;
        }

        setGuestLoading(true);

        postRequest(endpoints.guest_login, {}, (res) => {
            setGuestLoading(false);
            if (res[0]) {
                res = res[1];
                setAuthToken(res.session.token);
                checkAuth();
                navigate('/', {replace: true});
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (isAuth !== null && isAuth === true)
            navigate('/', { replace: true });
    }, [isAuth]);

    return (
        <>
            <Blur />
            <div className='login_app'>
                <Logo className='logo' />
                <br />
                <h1>
                    {/*Discover the food of your dreams!*/}
                    Experience the
                    Tastes of
                    Latin America
                </h1>
                <br />
                <h2>
                    Create an account or login
                </h2>
                <div className='login_opts'>
                    <button onClick={() => setActiveScreen(LoginScreen.LOGIN)} disabled={guestLoading}>
                        Login
                    </button>
                    <button onClick={postLoginGuest} disabled={guestLoading}>
                        Continue as guest
                        {
                            guestLoading && (
                                <div className='button_loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </button>
                    <span>
                        Or Create an account
                    </span>
                    <button className='singup_btn' onClick={() => setActiveScreen(LoginScreen.REGISTER) } disabled={guestLoading}>
                        Create an account
                    </button>
                </div>
                <div className='terms'>
                    By continuing, you accept our <Link>Terms and conditions</Link> and <Link>Privacy policy</Link>
                </div>
            </div>
            <LoginForm />
            <ForgotPassword />
            <ResetPassCode />
            <ResetPassword />
            <RegisterForm />
        </>
    );
}