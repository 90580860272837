import './banner_slider.css';
import protoBanner from '../../assets/img/proto_banner1.png';

export function BannerSlider() {
    return (
        <div className='banner_slider'>
            <div className='banner'>
                <img src={protoBanner} alt="" />
            </div>
        </div>
    );
}