import './payment_methods_modal.css';
import { APP_SCREENS, useApp } from "../../context/AppContext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { useEffect } from 'react';
import { PAYMENT_METHODS, usePaymentMethods } from '../../context/PaymentMethodsContext';
import { BarLoader } from '../BarLoader/BarLoader';
import { SavedCardItem } from '../SavedCardItem/SavedCardItem';
import { useAuth } from '../../context/AuthContext';
import { CashIcon } from '../../icons/CashIcon';
import { useRestaurant } from '../../context/RestaurantContext';
import { CashItem } from '../CashItem/CashItem';

export const PaymentMethodsModal = () => {
    //HOOKS
    const { currentAppScreen, changeAppScreen } = useApp();
    const { loadSavedCards, loadingCards, savedCards, setCardDeleteMode, cardDeleteMode } = usePaymentMethods();

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.PAYMENT_METHODS) {
            loadSavedCards();
        }
    }, [currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.PAYMENT_METHODS) ? 'app_modal active' : 'app_modal'} id="payment_methods_modal">
            <ModalHeader title={"Payment methods"} />
            <div className="payment_methods_body">
                <div className='payment_methods_container'>
                    <div className='header'>
                        <span>
                            Payment methods
                        </span>
                        <button className='light_btn' onClick={() => setCardDeleteMode((prev) => !prev)}>
                            { cardDeleteMode ? "Done" : "Delete card"}
                            
                        </button>

                        {
                            loadingCards && (
                                <div className='loader'>
                                    <BarLoader />
                                </div>
                            )
                        }
                    </div>
                    <div className='body'>
                        <CashItem />
                        {
                            savedCards && savedCards.map((card) => {
                                return <SavedCardItem card={card} key={"saved_card_" + card.id} />
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='btn_container'>
                <button className='light_btn' onClick={() => changeAppScreen(APP_SCREENS.CREDIT_CARD_FORM)}>
                    Add Credit / Debit card
                </button>
            </div>
        </div>
    );
}
