import axios from 'axios';
import { getAuthToken } from './AuthController';
import { endpoints, serverEnv } from './env';

//DEV
const api = axios.create({
    baseURL: serverEnv.backendUrl,
    headers: {
        'X-Mobile-App-Rest' : true,
    },
    withCredentials: true,
});

//PRODUCTION
/*const api = axios.create({
    baseURL: '/',
    headers: {
        'X-Mobile-App-Rest' : true,
    }
});*/

export function getMediaFile(file) {
    let uri = buildURIParams(endpoints.file_get, [file]);
    return api.defaults.baseURL + "/" + uri;
}

function buildURIParams(route, params) {
    let uri = route;
    for (let i = 0; i < params.length; i++) {
        uri = uri.replace(((i + 1) * - 1 ).toString(), params[i]);
    }
    return uri;
}

export function getRequest(url, data, callback) {
    let token = getAuthToken();

    api.get(url, { params: data, headers: {"X-Auth-Token" : token } }).then(res => {
        callback([true, res.data]);
    }).catch((res) => {
        callback([false, res]);
    });
}

export function getRequestParams(url, data, params, callback) {
    let uri = buildURIParams(url, params);

    api.get(uri, {params: data, headers: {}}).then(res => {
        callback([true, res.data]);
    }).catch((res) => {
        callback([false, res]);
    });
}

export function postRequest(url, data, callback) {
    let token = getAuthToken();

    api.post(url, data, { headers: {"X-Auth-Token" : token } }).then(res => {
        callback([true, res.data]);
    }).catch((res) => {
        callback([false, res]);
    });
}

export function postRequestParams(url, data, params, callback) {
    let uri = buildURIParams(url, params);
    let token = getAuthToken();
    
    api.post(uri, data, { headers: { "X-Auth-Token" : token } }).then(res => {
        callback([true, res.data]);
    }).catch((res) => {
        callback([false, res]);
    });
}