import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { CookieProvider } from './context/CookieContext';
import { ServerProvider } from './context/ServerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<CookieProvider>
			<AuthProvider>
				<ServerProvider>
					<App />
				</ServerProvider>
			</AuthProvider>
		</CookieProvider>
	</BrowserRouter>
);
         