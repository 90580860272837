import './index.css';
import { BannerSlider } from '../../components/BannerSlider/BannerSlider';
import { Menu } from '../../components/Menu/Menu';
import { AppNav } from '../../components/AppNav/AppNav';
import { AppHeader } from '../../components/AppHeader/AppHeader';
import { MenuProvider } from '../../context/MenuContext';
import { DeliveryMethodsMenu } from '../../components/DeliveryMethodsMenu/DeliveryMethodsMenu';
import { AppProvider } from '../../context/AppContext';
import { RestaurantProvider } from '../../context/RestaurantContext';
import { AddressCreateProvider } from '../../context/AddressCreateContext';
import { ProfileModal } from '../../components/ProfileModal/ProfileModal';
import { PersonalInformationModal } from '../../components/PersonalInformationModal/PersonalInformationModal';
import { ShoppingCartModal } from '../../components/ShoppingCartModal/ShoppingCartModal';
import { SingleProductProvider } from '../../context/SingleProductContext';
import { CartProvider } from '../../context/CartContext';
import { CheckoutModal } from '../../components/CheckoutModal/CheckoutModal';
import { PaymentMethodsModal } from '../../components/PaymentMethodsModal/PaymentMethodsModal';
import { AddCreditCard } from '../../components/AddCreditCard/AddCreditCard';
import { PaymentMethodsProvider } from '../../context/PaymentMethodsContext';
import { CheckoutProvider } from '../../context/ChekoutContext';
import { CheckoutLoadingModal } from '../../components/CheckoutLoadingModal/CheckoutLoadingModal';
import { OrdersModal } from '../../components/OrdersModal/OrdersModal';
import { OrdersProvider } from '../../context/OrdersContext';

export const IndexWrapper = () => {
    return (
        <RestaurantProvider>
            <MenuProvider>
                <AppProvider>
                    <PaymentMethodsProvider>
                        <OrdersProvider>
                            <SingleProductProvider>
                                <CartProvider>
                                    <AddressCreateProvider>
                                        <CheckoutProvider>
                                            <Index />
                                        </CheckoutProvider>
                                    </AddressCreateProvider>
                                </CartProvider>
                            </SingleProductProvider>
                        </OrdersProvider>
                    </PaymentMethodsProvider>
                </AppProvider>
            </MenuProvider>
        </RestaurantProvider>
    );
}

export const Index = () => {
    return (
        <div className='index_app'>
            <AppHeader />
            <BannerSlider />
            <Menu />
            <DeliveryMethodsMenu />
            <ProfileModal />
            <PersonalInformationModal />
            <ShoppingCartModal />
            <CheckoutModal />
            <PaymentMethodsModal />
            <AddCreditCard />
            <CheckoutLoadingModal />
            <OrdersModal />
            <AppNav  />
            {/*
            <ShoppingCart ref={shopppingCartRef} />
            <Checkout paymentMethodsRef={paymentMethodsRef} />
            <PaymentMethods ref={paymentMethodsRef} />
            */}
        </div>
    );
}