import './checkout_delivery.css';
import { DELIVERY_METHODS } from "../../context/AddressCreateContext";
import { APP_SCREENS, useApp } from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";
import { useRestaurant } from "../../context/RestaurantContext";

export const CheckoutDelivery = ({nochange}) => {
    const { user, getUserDeliveryAddress } = useAuth();
    const { changeAppScreen } = useApp();
    const { pickupSetting } = useRestaurant();

    return (
        <div className='checkout_delivery_container'>
            <div className='info'>
                <h2>
                    Delivery address
                </h2>
                {
                    !nochange && (
                        <button onClick={() => changeAppScreen(APP_SCREENS.DELIVERY_METHODS)}>
                            Change
                        </button>
                    )
                }
            </div>
            <h2>
                {
                    user && user.delivery.main_delivery_method === DELIVERY_METHODS.PICKUP && "Pickup"
                }
                {
                    user && user.delivery.main_delivery_method === DELIVERY_METHODS.DELIVERY && getUserDeliveryAddress(user.delivery.main_address).address
                }
            </h2>
            <span>
                {(user && user.delivery.main_delivery_method === DELIVERY_METHODS.PICKUP) && pickupSetting && pickupSetting.enable === 0 && "Pickup currently unavailable"}
            </span>
        </div>
    );
}