import './menu_product.css';
import { useEffect, useRef, useState } from 'react';
import { getMediaFile } from '../../ServerRequest';
import { useSingleProduct } from '../../context/SingleProductContext';
import { getLocalePrice } from '../../context/AppContext';

export const MenuProduct = (props) => {
    //HOOKS
    const { openProduct } = useSingleProduct();

    //REFS
    const productRef = useRef();
    const singleProductRef = useRef();

    //STATES
    const [imageUrl, setImageUrl] = useState("");

    //FUNCTIONS
    function openSingleProduct() {
        //singleProductRef.current.open();
        openProduct(props.product);
    }

    //EFFECTS
    useEffect(() => {
        if (props.product.image) {
            productRef.current.classList.add('image');
            setImageUrl(getMediaFile(props.product.image.file_id));
        }
    }, [props.product]);

    return (
        <>
            <div className='menu_product' ref={productRef} onClick={openSingleProduct}>
                <div className='menu_product_info'>
                    <div className='menu_product_info_name'>
                        <h2>{props.product.name}</h2>
                    </div>
                    <div className='menu_product_info_description'>
                        <p>
                            {props.product.description === "null" ? "" : props.product.description}
                        </p>
                    </div>
                    <div className='menu_product_info_price'>
                        <p>{getLocalePrice(props.product.price)}</p>
                    </div>
                </div>
                <div className='menu_product_pic'>
                    <div className='pic'>
                        <img src={imageUrl} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};