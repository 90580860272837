import './delivery_address_delete_modal.css';
import { APP_SCREENS, useApp } from "../../context/AppContext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { BarLoader } from '../BarLoader/BarLoader';
import { useAddressCreate } from '../../context/AddressCreateContext';

export const DeliveryAddressDeleteModal = () => {
    //HOOKS
    const { addressDeleteLoading, postDelete } = useAddressCreate();
    const { currentAppScreen, GoToPrevius } = useApp();

    return (
        <div className={(currentAppScreen === APP_SCREENS.DELIVERY_ADDRESS_DELETE) ? 'app_modal active' : 'app_modal'} id="delete_address_modal">
            <ModalHeader title='Delete address' />
            <div className="delete_address_body">
                <h2>
                    Are you sure you want to delete the address? This action cannot be undone.
                </h2>
                
            </div>
            <div className='btn_container' style={{padding: '0 5%'}}>
                <button className='secundary_btn' onClick={GoToPrevius} disabled={addressDeleteLoading}>
                    Cancel
                </button>
            </div>
            
            <div className='btn_container'>
                <button className='light_btn' disabled={addressDeleteLoading} onClick={postDelete}>
                    Delete
                </button>
                {
                    addressDeleteLoading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>
        </div>
    );
}