import './login_form.css';
import { useEffect, useRef, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { useNavigate } from 'react-router-dom';
import { LoginScreen, useLogin } from '../../pages/login/LoginContext';
import { useAuth } from '../../context/AuthContext';

export const LoginForm = () => {
    //HOOKS
    const navigate = useNavigate();
    const { ActiveScreen, setActiveScreen } = useLogin();
    const { login } = useAuth();

    //REFS
    const formRef = useRef();

    //STATES
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //FUNCTIONS
    function postLogin() {
        if (loading) {
            return;
        }

        setLoading(true);
        setErrorMsg("");
        
        login(email, password, (res) => {
            setLoading(false);
            if (res[0]) {
                res = res[1];
                if (res.success) {
                    navigate('/', { replace : true });
                } else {
                    if (res.error_code === "wrong_credentials") {
                        setErrorMsg("Wrong email or password");
                    } else if (res.error_code === "validator_exception" && res.errors.email) {
                        setErrorMsg("The email is required");
                    } else if (res.error_code === "validator_exception" && res.errors.password) {
                        setErrorMsg("The password is required");
                    } else {
                        setErrorMsg("Server error, please try again in a bit.");
                    }
                }
            } else {
                setErrorMsg("Server error, please try again in a bit.");
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (ActiveScreen === LoginScreen.LOGIN) {
            formRef.current.classList.add('active');
        } else {
            formRef.current.classList.remove('active');
        }
    }, [ActiveScreen]);

    return (
        <div className='login_form' ref={formRef}>
            <button className='close' onClick={() => setActiveScreen(LoginScreen.MAIN_SCREEN)}>
                <i className="fa-solid fa-chevron-down"></i>
            </button>
            <h2 className='title'>
                Welcome!
            </h2>
            <span className='subtitle'>
                Sign in to get started
            </span>
            <div className='form'>
                <div className='input_group'>
                    <label>Email</label>
                    <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='input_group'>
                    <label>Password</label>
                    <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <p className='forgot' onClick={() => setActiveScreen(LoginScreen.FORGOT_PASSWORD)} disabled={loading}>
                    Forgot password?
                </p>
                {
                    errorMsg !== "" && (
                        <span className='error_msg'>
                            {errorMsg}
                        </span>
                    )
                }
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                <button onClick={postLogin} disabled={loading}>
                    Login
                </button>
            </div>
        </div>
    );
};