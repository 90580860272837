import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { serverEnv } from "../../env";

export const AppleMapKit = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            addAnnotation: addAnnotation,
            removeAnnotation: removeAnnotation,
            deleteAllAnotations: deleteAllAnotations,
            geoCoder: geoCoder,
            centerToCoordinates: centerToCoordinates,
        }
    });

    //STATES
    const [map, setMap] = useState(null);

    //FUNCTIONS
    const geoCoder = (address, callback) => {
        const geocoder = new window.mapkit.Geocoder({
            language: "en",
            getsUserLocation: false,
            limitToCountries: "US",
        });

        geocoder._impl.lookup(address, (e, r) => {
            if (r.results.length > 0) {
                callback(r.results[0]);
            } else {
                callback(false);
            }
        });
    }

    const centerToCoordinates = (lat, long, zoomLevel) => {
        if (map === null) {
            setTimeout(() => {
                centerToCoordinates(lat, long, zoomLevel);
            }, 500);
            return;
        }

        let coordinates = new window.mapkit.Coordinate(lat, long);
        map._impl.setCenterAnimated(coordinates, true);

        if (zoomLevel) {
            map._impl.zoomLevel = zoomLevel;
        }
    }

    const addAnnotation = (lat, long, title, callback) => {
        if (map === null) {
            callback(false);
            return;
        }

        let coordinates = new window.mapkit.Coordinate(lat, long);
        let annotation = new window.mapkit.MarkerAnnotation(coordinates, {title: title});
        map.addAnnotation(annotation);
        callback(annotation);
    }
    
    const removeAnnotation = (annotation) => {
        try {
            map.removeAnnotation(annotation);
        } catch {

        }
    }

    const deleteAllAnotations = () => {
        if (map === null) {
            setTimeout(() => {
                deleteAllAnotations();
            }, 200);
            return;
        }

        map._impl.removeAnnotations(map._impl.annotations);
    }

    //EFFECTS
    useEffect(() => {
        let newMap;

        const script = document.createElement("script");
        script.src = "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js";
        script.async = true;
        script.onload = () => {
            window.mapkit.init({
                authorizationCallback: done => {
                    const token = serverEnv.appleToken;
                    done(token);
                }
            });

            newMap = new window.mapkit.Map(props.id);
            setMap(newMap);
            
            newMap.center = new window.mapkit.Coordinate(props.lat, props.long);
            newMap._impl.zoomLevel = 13;

            if (props.setMapLoadedCallback) {
                props.setMapLoadedCallback();
            }
        }

        document.body.appendChild(script);

        return () => {
            deleteAllAnotations();
            if (newMap) {
                newMap.destroy();
            }
            setMap(null);
            document.body.removeChild(script);
        }
    }, [props.id, props.lat, props.long]);

    return (
        <div className="apple_map_widget" style={{width: "100%", height: "100%"}} id={props.id}>

        </div>
    );
});