import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";

const OrdersContext = createContext(undefined);

export const OrdersProvider = ({children}) => {
    //HOOKS
    const { user } = useAuth();
    const { getRequest } = useServer();

    //STATES
    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);

    //FUNCTIONS
    const loadOrders = () => {
        if (ordersLoading) {
            return;
        }

        setOrdersLoading(true);
        getRequest(endpoints.get_orders, {}, (res) => {
            setOrdersLoading(false);
            if (res[0]) {
                res = res[1];
                setOrders(res.orders);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (user !== null) {
            loadOrders();
        }
    }, [user]);

    const values = {
        orders,
        ordersLoading,
        loadOrders,
    }

    return (
        <OrdersContext.Provider
            value={values}
        >
            {children}
        </OrdersContext.Provider>
    )
}

export const useOrders = () => useContext(OrdersContext);