import './add_credit_card.css';
import { APP_SCREENS, useApp } from "../../context/AppContext";
import { ModalHeader } from "../ModalHeader/ModalHeader";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useServer } from '../../context/ServerContext';
import { endpoints, serverEnv } from '../../env';
import { usePaymentMethods } from '../../context/PaymentMethodsContext';

const  stripePromise = loadStripe("pk_test_51K7MVlGyN0lWsyFcYj9NlaaxmrlcijnM6y7ABtVCRalYJTZKA8FFCO3cRdXd09J9RdyMBP01NpYS0EtFvRyEHfOf00q7RFpb23");

export const AddCreditCard = () => {
    //HOOKS
    const { currentAppScreen } = useApp();
    const { postRequest } = useServer();
    const { addCardLoading, setAddCardLoading } = usePaymentMethods();

    //STATES
    const [setupIntent, setSetupIntent] = useState("");

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.CREDIT_CARD_FORM) {
            setAddCardLoading(true);
            postRequest(endpoints.setup_intent_get, {}, (res) => {
                setAddCardLoading(false);
                if (res[0]) {
                    res = res[1];
                    setSetupIntent(res.client_secret);
                }
            })
        } else {
            setSetupIntent("");
        }
    }, [currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.CREDIT_CARD_FORM) ? 'app_modal active' : 'app_modal'}>
            <ModalHeader title={"Add credit / debit card"} loading={addCardLoading} />
            {
                setupIntent !== "" && (
                    <Elements stripe={stripePromise} options={{clientSecret: setupIntent}}>
                        <AddCreditCardBody />
                    </Elements>
                )
            }
        </div>
    );
}

const AddCreditCardBody = () => {
    //HOOKS
    const stripe = useStripe();
    const elements = useElements();
    const { previusAppScreen, currentAppScreen } = useApp();
    const { setAddCardLoading, addCardLoading } = usePaymentMethods();

    //FUNCTIONS
    const handleSubmit = async () => {
        setAddCardLoading(true);
        let prevHistory = "";

        for (let i = 0; i < previusAppScreen.length; i++) {
            if (previusAppScreen[i] === currentAppScreen) {
                return;
            }

            prevHistory += previusAppScreen[i];

            if (i != previusAppScreen.length - 1) {
                prevHistory += "_";
            }
        }

        const error = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: serverEnv.backendUrl + "/api/v2/stripe/setup/complete?previus=" + prevHistory,
            }
        });

        setAddCardLoading(false);

        if (error) {
            console.log(error);
        } else {

        }
    }

    return (
        <div className='credit_card_form_body'>
            <form>
                <PaymentElement />
            </form>
            <button className='light_btn' onClick={handleSubmit} disabled={addCardLoading} >
                Save credit / Debit card
            </button>
        </div>
    );
}
