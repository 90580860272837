import { getRequest, postRequest } from "./ServerRequest";

export function login(credentials, callback) {
    postRequest('login', credentials, (res) => {
        if (res[0] && res[1].success) {
            callback([true, res[1].token]);
            setCookie("auth_token", res[1].token);
            return;
        }

        callback([false, null]);
    });
}

export function loginGuest(callback) {
    postRequest('guest_login', {}, (res) => {
        if (res[0] && res[1].success) {
            callback([true, res[1].token]);
            setCookie("auth_token", res[1].token);
            return;
        }
    });

    callback([false, null]);
}

export function signUp(data, callback) {
    postRequest('register', data, (res) => {
        if (res[0] && res[1].success) {
            callback([true, res[1].token]);
            setCookie("auth_token", res[1].token);
            return;
        }

        callback([false, res[1]]);
    });
}

export function sendRecoverCode(email, callback) {
    let data = {
        email: email,
    };

    postRequest('send_recover', data, (res) => {
        callback (res[0] && res[1].success);
    });
}

export function checkRecoverCode(code, callback) {
    let data = {
        code: code,
    }

    postRequest('recover_code_check', data, (res) => {
        callback(res[0] && res[1].success);
    });
}

export function recoverPassword(code, password, callback) {
    let data = {
        code: code,
        password: password,
    }

    postRequest('recover_password', data, (res) => {
        callback([res[0] && res[1].success, res[1]]);
    });
}

export function checkAuth(callback) {
    postRequest('auth_check', {}, (res) => {
        return callback(res[0] && res[1].success);
    });
}

export function protectRoute() {
    checkAuth((res) => {
        if (!res) {
            window.location.href = '/login';
        }
    });
}

export function getAuthToken() {
    return getCookie("auth_token");
}

export function getUser(callback) {
    getRequest('user_get', {}, (res) => {
        callback(res[1]);
    });
}

//COOKIE FUNCTIONS
function setCookie(cname, cvalue){
    let d = new Date();

    d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return "";
}

function deleteCookie(cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}