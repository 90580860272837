import './address_details_modal.css';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { BarLoader } from '../BarLoader/BarLoader';
import { useAddressCreate } from '../../context/AddressCreateContext';
import { useEffect } from 'react';

export const AddressDetailsModal = () => {
    //HOOKS
    const { currentAppScreen, GoToPrevius, changeAppScreen } = useApp();
    const {
        address,
        apartment,
        setApartment,
        floor,
        setFloor,
        entrance,
        setEntrance,
        details,
        setDetails,
        detailsLoading,
        postChanges,
        addressEditMode,
    } = useAddressCreate();

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.DELIVERY_DETAILS && address === "") {
            GoToPrevius();
        } 
    }, [currentAppScreen, address]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.DELIVERY_DETAILS) ? 'app_modal active' : 'app_modal'} id='address_details_modal'>
            <ModalHeader title={addressEditMode ? 'Edit address details' : 'Add address details'} />
            <div className='address_details_body'>
                <div className='input_container'>
                    <label>Apartment</label>
                    <input type='text' className='primary_input' value={apartment} onChange={(e) => setApartment(e.target.value)} placeholder='Apartment' />
                </div>
                <div className='input_container'>
                    <label>Floor</label>
                    <input type='text' className='primary_input' value={floor} onChange={(e) => setFloor(e.target.value)} placeholder='Floor' />
                </div>
                <div className='input_container'>
                    <label>Entrance</label>
                    <input type='text' className='primary_input' value={entrance} onChange={(e) => setEntrance(e.target.value)} placeholder='Entrance' />
                </div>
                <div className='input_container'>
                    <label>Details</label>
                    <textarea className='primary_input' value={details} onChange={(e) => setDetails(e.target.value)}></textarea>
                </div>
                
                {
                    detailsLoading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
            </div>

            <div className='btn_container'>
                <button className='light_btn' disabled={detailsLoading} onClick={postChanges}>
                {addressEditMode ? 'Update address' : 'Save address'}
                </button>
            </div>
        </div>
    );
}