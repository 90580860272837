import './checkout_points.css';
import { useAuth } from '../../context/AuthContext';
import { useCheckout } from '../../context/ChekoutContext';

export const CheckoutPoints = () => {
    //HOOKS
    const { user } = useAuth();
    const { usePoints, setUsePoints } = useCheckout();

    return (
        <div className="checkout_payment_method">
        <div className="info">
                <h2>
                    Reward points
                </h2>
            </div>
            <div className='points_item'>
                <span>
                    Available points: { user && user.points.points }
                </span>
                <div>
                    Use points
                    <input type='checkbox' checked={usePoints} onClick={() => setUsePoints((prev) => !prev)} />
                </div>
            </div>
        </div>
    );
}