import { convertToLocalTime, getLocalePrice } from '../../context/AppContext';
import './order_item.css';

export const OrderItem = ({order}) => {
    return (
        <div className='order_item'>
            <h2>Order #{order.id}</h2>
            <span>{convertToLocalTime(order.created_at)} &nbsp; {getLocalePrice(order.total)}</span>
        </div>
    );
}