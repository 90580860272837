import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export function ProtectRoute(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuth } = useAuth();

    useEffect(() => {
        if (isAuth === null) {
            return;
        }

        if (location.pathname === props.path && !isAuth) {
            navigate("/login", { replace: true });
        }
    }, [location, isAuth]);

    return <></>;
}