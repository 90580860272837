import { sector } from "@turf/turf";
import { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AppContext = createContext(undefined);

export const APP_SCREENS = {
    MAIN : 0,
    DELIVERY_METHODS : 1,
    DELIVERY_ADD_ADDRESS : 2,
    DELIVERY_DETAILS: 3,
    DELIVERY_ADDRESS_DELETE: 4,
    PROFILE: 5,
    PERSONAL_INFORMATION: 6,
    SHOPPING_CART: 7,
    PRODUCT_MODAL: 8,
    CHECKOUT: 9,
    PAYMENT_METHODS: 10,
    CREDIT_CARD_FORM: 11,
    CHECKOUT_LOADING: 12,
    ORDERS: 13,
}

export const getLocalePrice = (price) => {
    return (price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const convertToLocalTime = (utcDateTime) => {
    const utcDate = new Date(utcDateTime);
    const localDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    return localDate.toLocaleString('en-US', options); // Formats the date to desired format
  };

export const AppProvider = ({children}) => {
    //HOOKS
    const [queryParameters, setSearchParams] = useSearchParams();

    //STATES
    const [previusAppScreen, setPreviusAppScreen] = useState([]);
    const [currentAppScreen, setCurrentAppScreen] = useState(APP_SCREENS.MAIN);


    //FUNCTIONS
    const GoToPrevius = () => {
        if (previusAppScreen.length > 0) {
            setCurrentAppScreen(previusAppScreen[previusAppScreen.length - 1]);
            const newPrev = previusAppScreen.slice(0, -1);
            setPreviusAppScreen(newPrev);
        } else {
            setCurrentAppScreen(APP_SCREENS.MAIN);
        }
    }

    const changeAppScreen = (screen) => {
        const index = previusAppScreen.indexOf(screen);

        if (index > -1) {
            let prevArray = previusAppScreen;
            for (let i = prevArray.length - 1; i >= index; i--) {
                prevArray.splice(i, 1);
            }
            setPreviusAppScreen(prevArray);
            setCurrentAppScreen(screen);
        } else {
            setPreviusAppScreen((prev) => [...prev, currentAppScreen]);
            setCurrentAppScreen(screen);
        }
    }

    const ResetAndGo = (screen) => {
        setPreviusAppScreen([APP_SCREENS.MAIN]);
        setCurrentAppScreen(screen);
    }

    //EFFECTS
    useEffect(() => {
        if (queryParameters.get("add_credit_cart") == 1) {
            setCurrentAppScreen(APP_SCREENS.PAYMENT_METHODS);

            if (queryParameters.get("previus") !== null) {
                const previus = queryParameters.get("previus").split("_");
                const previusScreens = [];

                for (let i = 0; i < previus.length; i++) {
                    if (Number.isInteger(parseInt(previus[i])) && parseInt(previus[i]) !== APP_SCREENS.PAYMENT_METHODS) {
                        previusScreens.push(parseInt(previus[i]));
                    }
                }
                
                setPreviusAppScreen(previusScreens);
            }

            setSearchParams((prev) => {
                prev.delete("add_credit_cart");
                prev.delete("previus");
                return prev;
            });
        }
    }, [queryParameters]);

    const values = {
        currentAppScreen,
        changeAppScreen,
        GoToPrevius,
        previusAppScreen,
        ResetAndGo,
    }

    return (
        <AppContext.Provider
            value={values}
        >
            {children}
        </AppContext.Provider>
    );
}

export const useApp = () => useContext(AppContext);