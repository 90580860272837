import { useAuth } from "../../context/AuthContext";
import { PAYMENT_METHODS, usePaymentMethods } from "../../context/PaymentMethodsContext";
import { useRestaurant } from "../../context/RestaurantContext";
import { CashIcon } from "../../icons/CashIcon";
import { BarLoader } from "../BarLoader/BarLoader";

export const CashItem = ({displayMode}) => {
    //HOOKS
    const { user } = useAuth();
    const { setDefaultCash, cardLoading } = usePaymentMethods();
    const { cashSetting } = useRestaurant();

    return (
        <div className="saved_card_item" onClick={() => { if (!displayMode) setDefaultCash() }}>
            <div className="icon">
                <CashIcon />
            </div>

            <span>
                Cash
                <br />
                {
                    cashSetting && cashSetting.enable === 0 && (
                        <span>
                            Cash payments are currently unavailable
                        </span>
                    )
                }
            </span>

            {
                !displayMode && (
                    <input type="radio" checked={user && (user.payment.payment_method === PAYMENT_METHODS.CASH)} />
                )
            }


            {
                cardLoading === "cash" && (
                    <div className='loader'>
                        <BarLoader />
                    </div>
                )
            }
        </div>  
    );
}