import './shopping_cart_modal.css';
import { APP_SCREENS, getLocalePrice, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { useCart } from '../../context/CartContext';
import { useEffect } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { ShoppingCartItem } from '../ShoppingCartItem/ShoppingCartItem';

export const ShoppingCartModal = () => {
    //HOOKS
    const { currentAppScreen, changeAppScreen } = useApp();
    const { cart, getCart, cartLoading } = useCart();

    //EFFECTS
    useEffect(() => {
        if (currentAppScreen === APP_SCREENS.SHOPPING_CART) {
            getCart();
        }
    }, [currentAppScreen]);

    return (
        <div className={(currentAppScreen === APP_SCREENS.SHOPPING_CART) ? 'app_modal active' : 'app_modal'} id='shopping_cart_modal'>
            <ModalHeader title={'Shopping cart'} />
            <div className='shopping_cart'>
                {
                    cartLoading && (
                        <div className='main_loader'>
                            <BarLoader />
                        </div>
                    )
                }
                {
                    cart && cart.items.items.map((item) => {
                        return <ShoppingCartItem item={item} key={"cart_item_" + item.id} />
                    })
                }
            </div>
            <div className='footer'>
                <h2>
                    Total:
                    <span>
                        { cart && getLocalePrice(cart.total)}
                    </span>
                </h2>
                <button className='light_btn' disabled={cartLoading} onClick={() => changeAppScreen(APP_SCREENS.CHECKOUT)}>
                    Checkout
                </button>
            </div>
        </div>
    );
}