import { createContext, useContext, useEffect, useState } from "react";
import { useServer } from "./ServerContext";
import { endpoints } from "../env";

const MenuContext = createContext(undefined);

export const MenuProvider = ({children}) => {
    //HOOKS
    const { getRequestParams } = useServer();

    //STATES
    const [menu, setMenu] = useState({});

    //FUNCTIONS
    const getMenu = () => {
        getRequestParams(endpoints.menu_get, {}, [1], (res) => {
            if (res[0]) {
                setMenu(res[1].menu);
            }
        });
    }

    const getProduct = (id) => {
        if (!menu.sections) {
            return setTimeout(() => {
                getProduct(id);
            }, 300);
        }

        for (let i = 0; i < menu.sections.length; i++) {
            let product = menu.sections[i].products.find(product => product.id === id) || null;

            if (product !== null)
                return product;
        }

        return null;
    }

    //EFFECTS
    useEffect(() => {
        getMenu();
    }, []);

    const values = {
        menu,
        getMenu,
        getProduct,
    }

    return (
        <MenuContext.Provider
            value={values}
        >
            {children}
        </MenuContext.Provider>
    );
}

export const useMenu = () => useContext(MenuContext);