import "./checkout_price_summary.css";
import { getLocalePrice } from "../../context/AppContext";
import { useCart } from "../../context/CartContext";
import { useCheckout } from "../../context/ChekoutContext";
import { DELIVERY_METHODS, useAddressCreate } from "../../context/AddressCreateContext";
import { useAuth } from "../../context/AuthContext";

export const CheckoutPriceSummary = () => {
    //HOOKS
    const { cart } = useCart();
    const { tip, usePoints, pointsToUse } = useCheckout();
    const { globalCurrentAddress } = useAddressCreate();
    const { user } = useAuth();

    //STATES

    return (
        <div className="checkout_price_summary">
            <div className="info">
                <h2>
                    Order summary
                </h2>
            </div>
            <div className="item">
                <p>
                    Subtotal
                </p>
                <span>
                    { cart && getLocalePrice(cart.subtotal) }
                </span>
            </div>
            {
                user && user.delivery.main_delivery_method === DELIVERY_METHODS.DELIVERY && (
                    <div className="item">
                        <p>
                            Delivery
                        </p>
                        <span>
                            {globalCurrentAddress && getLocalePrice(globalCurrentAddress.area.price)}
                        </span>
                    </div>
                )
            }
            <div className="item">
                <p>
                    Tip
                </p>
                <span>
                    { getLocalePrice(parseFloat((tip !== "") ? tip : "0")) }
                </span>
            </div>
            <div className="item">
                <p>
                    Tax
                </p>
                <span>
                    { cart && getLocalePrice(cart.tax) }
                </span>
            </div>

            {
                user && usePoints && (
                    <div className="item">
                        <p>
                            Points
                        </p>
                        <span>
                            -{getLocalePrice((pointsToUse) / 100)}
                        </span>
                    </div>
                )
            }
        </div>
    );
}