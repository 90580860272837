import './product_option_selection.css';
import { useSingleProduct } from '../../context/SingleProductContext';
import { getLocalePrice } from '../../context/AppContext';

export const ProductOptionSelection = ({choice}) => {
    //HOOKS
    const { currentProductOptions, setSelectedOption } = useSingleProduct();

    return (
        <div className="product_option_selection" onClick={() => setSelectedOption(choice.dish_option_id, choice.id)}>
            <div className='info'>
                <h2>{choice.name}</h2>
                {
                    choice.price > 0 && (
                        <span>
                            {getLocalePrice(choice.price)}
                        </span>
                    )
                }
            </div>
            <div>
                <input type="radio" checked={currentProductOptions && currentProductOptions["option_" + choice.dish_option_id].choice === choice.id} />
            </div>
        </div>
    );
}