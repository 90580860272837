import './pickup_item.css';
import { BagIcon } from '../../icons/BagIcon';
import { useAuth } from '../../context/AuthContext';
import { DELIVERY_METHODS, useAddressCreate } from '../../context/AddressCreateContext';
import { useRestaurant } from '../../context/RestaurantContext';

export const PickupItem = () => {
    //HOOKS
    const { user } = useAuth();
    const { setPickupMain } = useAddressCreate();
    const { pickupSetting } = useRestaurant();

    //FUNCTIONS
    const setMain = () => {
        if ((pickupSetting && pickupSetting.enable !== 0) && !(user && user.delivery.main_delivery_method === DELIVERY_METHODS.PICKUP)) {
            setPickupMain();
        }
    }

    return (
        <div className='pickup_item' onClick={setMain}>
            <div className='icon'>
                <BagIcon />
            </div>
            <span>
                Pickup
                <br />
                {
                    pickupSetting && pickupSetting.enable === 0 && (
                        <span>
                            Pickup currently unavailable
                        </span>
                    )
                }
            </span>
            <input type='radio' checked={user && user.delivery.main_delivery_method === DELIVERY_METHODS.PICKUP} />
        </div>
    );
}
