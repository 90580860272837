import './register_form.css';
import { useEffect, useRef, useState } from 'react';
import { BarLoader } from '../BarLoader/BarLoader';
import { Link, useNavigate } from 'react-router-dom';
import { LoginScreen, useLogin } from '../../pages/login/LoginContext';
import { useServer } from '../../context/ServerContext';
import { endpoints } from '../../env';
import { useAuth } from '../../context/AuthContext';

export const RegisterForm = () => {
    //HOOKS
    const navigate = useNavigate();

    const {
        ActiveScreen,
        setActiveScreen,
    } = useLogin();

    const { postRequest } = useServer();
    const { setAuthToken, checkAuth } = useAuth();

    //REFS
    const formRef = useRef();

    //STATES
    const [loading, setLoading] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [password, setPassword] = useState("");

    //FUNCTIONS
    const postRegister = () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setMsgError("");

        const data = {
            email,
            name,
            password,
            birthdate,
        }

        postRequest(endpoints.register, data, (res) => {
            setLoading(false);

            if (res[0]) {
                res = res[1];
                
                if (res.success) {
                    setAuthToken(res.session.token);
                    checkAuth();
                    navigate('/', {replace: true});
                } else {
                    if (res.error_code === "validator_exception") {
                        if (res.errors.email) {
                            setMsgError("The email is required");
                        } else if (res.errors.name) {
                            setMsgError("The name is required");
                        } else if (res.errors.birthdate) {
                            setMsgError("The birthdate is required")
                        } else if (res.errors.password) {
                            setMsgError("The password is required");
                        } else {
                            setMsgError("Something went wrong, please try again in a bit");
                        }
                    } else if (res.error_code === "used_email") {
                        setMsgError("The email is already in use");
                    } else {
                        setMsgError("Server error, please try again in a bit.");
                    }
                }
            } else {
                setMsgError("Server error, please try again in a bit.");
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        if (ActiveScreen === LoginScreen.REGISTER) {
            formRef.current.classList.add('active');
        } else {
            formRef.current.classList.remove('active');
        }
    }, [ActiveScreen]);

    return (
        <div className='register_form' ref={formRef}>
            <button className='close' onClick={() => setActiveScreen(LoginScreen.MAIN_SCREEN)}>
                <i className="fa-solid fa-chevron-down"></i>
            </button>
            <h2 className='title'>
                Welcome!
            </h2>
            <span className='subtitle'>
                Sign up to get started
            </span>
            <div className='form'>
                <div className='input_group'>
                    <label>Email</label>
                    <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='input_group'>
                    <label>Name</label>
                    <input type='text' value={name} onChange={(e) => setName(e.target.value)}/>
                </div>
                <div className='input_group'>
                    <label>Birthdate</label>
                    <input type='date' value={birthdate} onChange={(e) => setBirthdate(e.target.value)} />
                </div>
                <div className='input_group'>
                    <label>Password</label>
                    <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                {
                    msgError !== "" && (
                        <span className='error_msg'>
                            {msgError}
                        </span>
                    )
                }
                {
                    loading && (
                        <div className='loader'>
                            <BarLoader />
                        </div>
                    )
                }
                <button onClick={postRegister} disabled={loading}>
                    Sign up
                </button>
            </div>
            <div className='terms'>
                By continuing, you accept our <Link>Terms and conditions</Link> and <Link>Privacy policy</Link>
            </div>
        </div>
    );
};