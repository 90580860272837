import './profile_modal.css';
import ProfilePic from '../../assets/img/nophoto.png';
import { APP_SCREENS, useApp } from '../../context/AppContext';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import { useAuth } from '../../context/AuthContext';
import { RightArrowIcon } from '../../icons/RightArrowIcon';

export const ProfileModal = () => {
    //HOOKS
    const { currentAppScreen, changeAppScreen } = useApp();
    const { user, logout } = useAuth();

    return (
        <div className={(currentAppScreen === APP_SCREENS.PROFILE) ? 'app_modal active' : 'app_modal'} id='profile_modal'>
            <ModalHeader title={"Profile"} />
            <div className='profile_body'>
                <div className='profile_container'>
                    <div className='profile_picture'>
                        <img src={ProfilePic} alt='' />
                    </div>
                    <div className='header'>
                        <h2>
                            {user && user.contact.name}   
                        </h2>
                    </div>
                    <button className='item' onClick={() => changeAppScreen(APP_SCREENS.PERSONAL_INFORMATION)}>
                        Personal information
                        <RightArrowIcon />
                    </button>
                    <button className='item'>
                        Orders
                        <RightArrowIcon />
                    </button>
                    <button className='item' onClick={() => changeAppScreen(APP_SCREENS.DELIVERY_METHODS)}>
                        Delivery Preferences
                        <RightArrowIcon />
                    </button>
                    <button className='item' onClick={logout}>
                        Logout
                        <RightArrowIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}